import React from 'react'
import contactIllustration from "../assets/svg/contact-illustration.svg"

const Contact = () => {
    const sectionStyles = "mb-8 flex flex-col";
    const labelStyles = "mb-3 block text-sm font-semibold leading-none";
    const inputStyles = "flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-0 focus:ring-0 border border-border-base rounded focus:border-primary h-12";

    return (
        <div className="w-full bg-gray-100">
            <div className="mx-auto flex w-full max-w-7xl flex-col px-5 py-10 pb-20 md:flex-row md:pb-10 lg:py-14 lg:px-8 lg:pb-14 gap-6">
                {/* Contact Info Section */}
                <div className="order-2 w-full shrink-0 rounded-lg bg-white p-5 md:order-1 md:w-72 lg:w-96">
                    <div className="mb-8 flex w-full items-center justify-center overflow-hidden">
                        <img alt="Contact" width={299} height={232} className="h-auto w-full" src={contactIllustration} />
                    </div>
                    <div className={sectionStyles}>
                        <span className="mb-3 font-semibold">Address</span>
                        <span className="text-sm">
                            <p>NY State Thruway, New York, USA</p>
                        </span>
                    </div>
                    <div className={sectionStyles}>
                        <span className="mb-3 font-semibold">Phone</span>
                        <span className="text-sm">+129290122122</span>
                    </div>
                    <div className={sectionStyles}>
                        <span className="mb-3 font-semibold">Email Address</span>
                        <span className="text-sm">demo@demo.com</span>
                    </div>
                    <div className={sectionStyles}>
                        <span className="mb-3 font-semibold">Website</span>
                        <div className="flex items-center justify-between">
                            <span className="text-sm">https://redq.io</span>
                            <p to={'/'} className="text-sm font-semibold text-primary hover:text-primary-hover focus:text-blue-500 focus:outline-none">Visit This Site</p>
                        </div>
                    </div>
                    <div className={sectionStyles}>
                        <span className="mb-4 font-semibold">Follow Us</span>
                        <div className="flex items-center justify-start gap-2">
                            <p className="mr-8 last:mr-0 rtl:ml-8 rtl:last:ml-0">
                                <i className="ri-instagram-fill text-xl"></i>
                            </p>
                            <p className="mr-8 last:mr-0 rtl:ml-8 rtl:last:ml-0">
                                <i className="ri-facebook-circle-fill text-xl"></i>
                            </p>
                            <p className="mr-8 last:mr-0 rtl:ml-8 rtl:last:ml-0">
                                <i className="ri-twitter-fill text-xl"></i>
                            </p>
                        </div>
                    </div>
                </div>
                {/* Contact Form Section */}
                <div className="order-1 mb-8 w-full rounded-lg bg-white p-5 md:order-2 md:mb-0 md:p-8 md:ml-7 rtl:md:mr-7 lg:ml-9 rtl:lg:mr-9">
                    <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">How can we improve your experience?</h1>
                    <form>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="name" className={labelStyles}>Name</label>
                                <input id="name" name="name" type="text" className={inputStyles} />
                            </div>
                            <div>
                                <label htmlFor="email" className={labelStyles}>Email</label>
                                <input id="email" name="email" type="email" className={inputStyles} />
                            </div>
                        </div>
                        <div className="my-6">
                            <label htmlFor="subject" className={labelStyles}>Subject</label>
                            <input id="subject" name="subject" type="text" className={inputStyles} />
                        </div>
                        <div className="my-6">
                            <label htmlFor="description" className={labelStyles}>Description</label>
                            <textarea id="description" name="description" className="flex w-full appearance-none items-center rounded px-4 py-3 text-sm text-heading transition duration-300 ease-in-out focus:outline-0 focus:ring-0 border border-border-base focus:border-primary" rows="4"></textarea>
                        </div>
                        <button
                            className="inline-flex items-center justify-center shrink-0 -none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-primary-700 bg-primary text-white border border-transparent hover:bg-primary-hover px-5 py-0 h-12">Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
