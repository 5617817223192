import {
  ADD_CART,
  DELETE_CART,
  FETCH_CART_ITEMS,
  GET_STORES,
  TEMP_CART,
} from "../contstants/apis";
import { setCartData, setTempCartData } from "../redux/reducers/products";

export const fetchShops = async (makeRequest, page, pageSize, cb) => {
  await makeRequest({
    url: `${GET_STORES}?page=${page - 1}&size=${pageSize}`,
    onSuccessCallback: (res) => {
      if (cb) {
        cb(res);
      }
    },
    isToastVisible: false,
  });
};

export const fetchCartItems = async (makeRequest, dispatch, bool, cb) => {
  await makeRequest({
    url: FETCH_CART_ITEMS,
    reduxAction: setCartData,
    onSuccessCallback: (data) => {
      if (data?.code === 400) {
        dispatch(setCartData(null));
      }
      if(cb){
        cb(data?.data ?? {});
      }
    },
    isToastVisible: false,
    toggleLoader: bool,
  });
};

export const fetchTempCartItems = async (makeRequest, dispatch, uuid) => {
  makeRequest({
    url: `${TEMP_CART}/${uuid}`,
    onSuccessCallback: (res) => {
      if (res?.data?.items?.length > 0) {
        dispatch(setTempCartData(res.data));
      } else {
        dispatch(setTempCartData(null));
      }
    },
    isToastVisible: false,
  });
};

export const updateTempCartToCart = async (makeRequest, data, dispatch) => {
  const formData = data?.items?.map((i) => ({
    productId: i?.productId,
    quantity: i?.quantity,
  }));
  await makeRequest({
    url: `${ADD_CART}/${data?.storeId}`,
    method: "POST",
    data: formData,
    onSuccessCallback: () => {
      dispatch(setTempCartData(null));
      dispatch(setCartData(null));
      // if (cb) {
      //   cb();
      // }
    },
    isToastVisible: false,
    toggleLoader: false,
  });
};

export const handleTempCartDeletion = async (makeRequest, dispatch, id) => {
  await makeRequest({
    url: `${TEMP_CART}/${id}`,
    method: "DELETE",
    onSuccessCallback: () => {
      fetchCartItems(makeRequest, dispatch);
      dispatch(setTempCartData(null));
    },
    isToastVisible: false,
  });
};

export const handleCartDeletion = async (
  makeRequest,
  dispatch,
  id,
  bool,
  guestCart
) => {
  await makeRequest({
    url: `${DELETE_CART}/${id}`,
    method: "DELETE",
    onSuccessCallback: async() => {
      dispatch(setCartData(null));
      if (guestCart) {
        await updateTempCartToCart(makeRequest, guestCart, dispatch);
      }
    },
    isToastVisible: false,
    toggleLoader: bool,
  });
};

export const handleDeleteCart = async (
  makeRequest,
  dispatch,
  isLoggedIn,
  userCartId,
  tempCartId,
  navigate,
  showDeleteCard,
  setShowDeleteCard,
  uuid
) => {
  await makeRequest({
    url: isLoggedIn
      ? `${DELETE_CART}/${userCartId}`
      : `${TEMP_CART}/${tempCartId}`,
    method: "DELETE",
    onSuccessCallback: () => {
      navigate(`/shop-panel/${showDeleteCard?.id}`, {
        state: { shop: showDeleteCard },
      });
      setShowDeleteCard(null);
      if (isLoggedIn) {
        fetchCartItems(makeRequest, dispatch);
        dispatch(setCartData(null));
      } else {
        fetchTempCartItems(makeRequest, dispatch, uuid);
        dispatch(setTempCartData(null));
      }
    },
    isToastVisible: false,
  });
  setShowDeleteCard(null);
};
