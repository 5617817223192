import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../assets/svg/home.svg';
import rightArrowIcon from '../assets/svg/rightArrow.svg';

const SectionHeader = ({ title, breadcrumbText, breadcrumbLink }) => {
    return (
        <div className="flex w-full justify-center bg-slate-200 py-20 md:min-h-[250px] lg:min-h-[288px]">
            <div className="relative flex w-full flex-col items-center justify-center">
                <h1 className="text-center text-xl font-bold md:text-2xl lg:text-3xl 2xl:text-[40px]">
                    <span className="mb-3 block font-bold md:mb-4 lg:mb-5 2xl:mb-7">{title}</span>
                </h1>
                <div className="flex items-center">
                    <ul className="flex w-full items-center overflow-hidden">
                        <li className="px-2.5 text-sm transition duration-200 ease-in hover:text-primary ltr:first:pl-0 ltr:last:pr-0 rtl:first:pr-0 rtl:last:pl-0">
                            <Link className="inline-flex items-center" to={breadcrumbLink}>
                                <img src={homeIcon} className='mr-2' alt="" />
                                Home
                            </Link>
                        </li>
                        <li className="text-base">
                            <img src={rightArrowIcon} className='mr-2' alt="" />
                        </li>
                        <li className="px-2.5 text-sm transition duration-200 ease-in ltr:first:pl-0 ltr:last:pr-0 rtl:first:pr-0 rtl:last:pl-0">{breadcrumbText}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SectionHeader;
