import 'remixicon/fonts/remixicon.css'
// import 'animate.css';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './layouts/admin';
import './App.css';
import HomePage from './screens/Landing';
import SignIn from './screens/SignIn';
import SignUp from './screens/signup';
import AllShops from './screens/AllShops';
import Contact from './components/Contact';
import ShopPanel from './screens/ShopPanel';
import Checkout from './screens/Checkout';
import OrderStatus from './screens/OrderStatus';
import TermCondition from './screens/Term&Condition';
import VendorPolicy from './screens/VendorPolicy';
import CustomerRefund from './screens/CustomerRefund';
import FAQ from './screens/FAQ';
import PrivacyPolicy from './screens/PrivacyPolicy';
import OrderDetails from './screens/OrderDetails';


function App() {
  // const { isLoggedIn } = useSelector((state) => state.authSlice);

  return (
    <Router>
      <Routes>
        {/* Conditionally render routes based on isLoggedIn */}
        {/* test */}
        {/* {isLoggedIn ? ( */}
          {/* // Routes for logged-in users */}
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<AllShops />} />
            <Route path="landing-page" element={<HomePage />} />
            <Route path="shop-panel/:id" element={<ShopPanel />}/>
            <Route path="contact" element={<Contact />} />
            <Route path='checkout' element={<Checkout />} />
            {/* <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} /> */}
            <Route path="order-status" element={<OrderStatus />} />
            <Route path="order-detail" element={<OrderDetails/>} />
            <Route path='terms-and-conditions' element={<TermCondition />} />
            <Route path='vendor-policy' element={<VendorPolicy />} />
            <Route path='customer-refund-policy' element={<CustomerRefund />} />
            <Route path='faqs' element={<FAQ />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
          </Route>
        {/* ) : (
          // Routes for unauthenticated users
          <Route path="/auth" element={<AuthPageLayout />}>
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
          </Route>
        )} */}

        {/* Redirect to /auth if the user is not logged in */}
        {/* {!isLoggedIn && <Route path="/*" element={<Navigate to="/auth/signin" />} />} */}
        {/* {isLoggedIn && <Route path="/auth/*" element={<Navigate to="/" />} />} */}
      </Routes>
    </Router >
  );
}

export default App;
