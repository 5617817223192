import React from 'react'

const PrivacyPolicy = () => {
    return (
        <section className="mx-auto w-full max-w-1920 bg-white px-4 py-8 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
            <header className="mb-10 sm:mt-2 lg:mb-14 xl:mt-4">
                <h1 className="mb-4 text-xl font-bold  sm:mb-5 sm:text-3xl md:text-2xl 2xl:mb-7 2xl:text-4xl">Privacy Policy</h1>
                <p className="px-0.5 text-sm md:text-base 2xl:text-lg">01/01/2020</p>
            </header>
            <div className="flex flex-col md:flex-row">
                <nav className="mb-8 md:mb-0 md:w-72 xl:w-3/12">
                    <ol className="sticky z-10 md:top-16 lg:top-22">
                        <li>
                            <a className="inline-flex cursor-pointer py-3 uppercase text-sub-heading">Purpose</a>
                        </li>
                        <li>
                            <a className="inline-flex cursor-pointer py-3 uppercase text-sub-heading">What is Personal Data?</a>
                        </li>
                        <li>
                            <a className="inline-flex cursor-pointer py-3 uppercase text-sub-heading">Personal Data Collected</a>
                        </li>
                        <li>
                            <a className="inline-flex cursor-pointer py-3 uppercase text-sub-heading">Accessing your Personal Data</a>
                        </li>
                        <li>
                            <a className="inline-flex cursor-pointer py-3 uppercase text-sub-heading">Complaints</a>
                        </li>
                        <li>
                            <a className="inline-flex cursor-pointer py-3 uppercase text-sub-heading">Owner and Data Controller</a>
                        </li>
                    </ol>
                </nav>
                <div className="md:w-9/12 md:pb-10 md:pl-8 rtl:md:pr-8">
                    <div className="mb-10">
                        <h2 className="mb-4 text-lg font-bold  md:text-xl lg:text-2xl">Purpose</h2>
                        <div className="leading-loose">
                            <p>Little &amp; Big is committed to protecting your privacy because we are committed to valuing people. Our Privacy Policy below sets out how your personal information is collected, used and protected. The&nbsp;<a href="http://demo.dummy">Demo Country Privacy Principles</a>&nbsp;also apply to us.</p>
                            <p>This Privacy Policy describes our policies and procedures on the collection, holding, use and disclosure of your personal information and should be read together with our&nbsp;Terms and Conditions. By providing your personal information you consent to our collection, use and disclosure of that information in accordance with this Privacy Policy.</p>
                        </div>
                    </div>
                    <div className="mb-10">
                        <h2 className="mb-4 text-lg font-bold  md:text-xl lg:text-2xl">What is Personal Data?</h2>
                        <div className="leading-loose">
                            <p>When used in this Policy, "personal information" has the meaning given in the Privacy Act. Generally, it means any information or an opinion that could be used to identify you.</p>
                        </div>
                    </div>
                    <div className="mb-10"><h2 className="mb-4 text-lg font-bold  md:text-xl lg:text-2xl">Personal Data Collected</h2>
                        <div className="leading-loose">
                            <p>Personal Data collected for the following purposes and using the following services:</p>
                            <ol>
                                <li>Google Analytics: Cookies; Usage Data</li>
                                <li>Contact form: email address; first name; phone number</li>
                                <li>Mailing list or newsletter: email address; first name</li>
                            </ol>
                        </div>
                    </div>
                    <div className="mb-10"><h2 className="mb-4 text-lg font-bold  md:text-xl lg:text-2xl">Accessing your Personal Data</h2>
                        <div className="leading-loose">
                            <p>You may request access to your personal information collected by us, and ask that we correct that personal information. You can ask for access or correction by contacting us and we will usually respond within 30 days. If we refuse to give you access to, or correct, your personal information, we will notify you in writing setting out the reasons.</p>
                        </div>
                    </div>
                    <div className="mb-10">
                        <h2 className="mb-4 text-lg font-bold md:text-xl lg:text-2xl">Complaints</h2>
                        <div className="leading-loose">
                            <p>If you believe your privacy has been breached or you have a complaint about how we have handled your personal information, please contact us in writing.  We will respond within a reasonable period (usually within 30 days).</p>
                        </div>
                    </div>
                    <div className="mb-10">
                        <h2 className="mb-4 text-lg font-bold md:text-xl lg:text-2xl">Owner and Data Controller</h2>
                        <div className="leading-loose">
                            <p>The Commons</p>
                            <p>20-40 demo St,</p>
                            <p>Jon doe NSW 2008</p>
                            <p>Country</p>
                            <p>demo@demo.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy
