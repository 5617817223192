import React, { useEffect, useState } from "react";
import ShopCard from "../../components/ShopCard";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import dummyImg from "../../assets/images/dummy.jpg";
import DeleteCard from "../../components/DeleteCard";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import {
  fetchCartItems,
  fetchShops,
  handleCartDeletion,
  handleDeleteCart,
  handleTempCartDeletion,
  updateTempCartToCart,
} from "../../services/shop.service";
import useConfirm from "../../hooks/useConfirm";

let page = 1;
const pageSize = 20;

const AllShops = () => {
  const { t } = useTranslation("AllShops");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCheckoutRoute = localStorage?.getItem("isCheckoutRoute");
  const [shopData, setShopData] = useState([]);
  const [showDeleteCard, setShowDeleteCard] = useState(null);
  const { makeRequest } = useAxios();
  const { cartData, tempCartData } = useSelector((state) => state.products);
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const { confirm, ConfirmModal } = useConfirm();


  const deleteCart = () => {
    const uuid = localStorage?.getItem("uuid");
    handleDeleteCart(
      makeRequest,
      dispatch,
      isLoggedIn,
      cartData?.data?.id,
      tempCartData?.data?.id,
      navigate,
      showDeleteCard,
      setShowDeleteCard,
      uuid
    );
  };

  useEffect(() => {
    fetchShops(makeRequest, page, pageSize, setShopData);
  }, []);

  const handleCancelClick = () => {
    setShowDeleteCard(null);
  };

  const handleShopNav = (shop) => {
    navigate(`/shop-panel/${shop?.id}`, { state: { shop: shop } });
  };

  const handleSelectedShop = (shop) => {
    if (
      (isLoggedIn ? cartData : tempCartData)?.data?.id &&
      shop.id !== (isLoggedIn ? cartData : tempCartData)?.data?.storeId
    ) {
      setShowDeleteCard(shop);
    } else {
      handleShopNav(shop);
    }
  };

  const handlePageChange = (p) => {
    page = p;
    fetchShops(makeRequest, page, pageSize, setShopData);
  };


  // guest login handles
  const handleCartLogics = async (userCart) => {

    // if(userCart?.storeId && !tempCartData?.data?.storeId){
    //   return navigate(`/shop-panel/${userCart?.storeId}`);
    // }

    //if no data in tempcart no need to proceed
    if (!tempCartData) {
      // fetchShops(makeRequest, page, pageSize, setShopData);
      // dispatch(setLoader(false));
      //stop loader please
      return;
    }

    //if data in tempcart
    if (tempCartData?.data?.storeId) {
      // let userCart = cartData?.data;
      let guestCart = tempCartData?.data;
      // now match the store ids

      //if no data in user cart , just adding temp cart data
      if (!userCart?.storeId) {
        await updateTempCartToCart(makeRequest, guestCart, dispatch);
        await handleTempCartDeletion(makeRequest, dispatch, guestCart?.id);
        return;
      }


      if (userCart?.storeId == guestCart?.storeId) {
        const data = guestCart?.items.map((i) => {
          // find product in user cart and then get qty
          const productQtyInUserCart =
            userCart?.items?.find((u) => u.productId === i.productId)
              ?.quantity ?? 0;
          return {
            productId: i?.productId,
            quantity: i?.quantity + productQtyInUserCart,
          };
        });


        await updateTempCartToCart(
          makeRequest,
          { ...guestCart, items: data },
          dispatch
        );
      }

      //if doesnt match please show popup to get confirmation from user to keep current cart items or continue with previosly added items
      if (userCart?.storeId !== guestCart?.storeId) {
        const keepPreviousData = await confirm(
          "Are you sure you want to proceed?"
        );
        if (!keepPreviousData) {
          await handleCartDeletion(
            makeRequest,
            dispatch,
            userCart?.id,
            false,
            guestCart
          );
          navigate(isCheckoutRoute ? isCheckoutRoute : `/shop-panel/${guestCart?.storeId}`);
          
        } else {
          navigate(`/shop-panel/${userCart?.storeId}`);
        }
      }

      await handleTempCartDeletion(makeRequest, dispatch, guestCart?.id);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      //getting user cart details
      fetchCartItems(makeRequest, dispatch, false, handleCartLogics );
    
    }
  }, [isLoggedIn]);

  return (
    <>
      <div className="mx-auto flex w-full min-h-screen max-w-6xl flex-col p-8 px-5 pt-14 lg:px-6 2xl:px-8">
        <h3 className="mb-8 text-2xl font-bold">{t("allShops")}</h3>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {shopData?.data?.data.map((shop, index) => (
            <ShopCard
              onClick={() => handleSelectedShop(shop)}
              key={index}
              shopName={shop.storeName}
              image={shop.storeLogoUrl ? shop.storeLogoUrl : dummyImg}
              address={shop.address || "Not Available"}
              storeId={shop.id}
            />
          ))}
          {showDeleteCard?.id && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <DeleteCard
                onCancel={handleCancelClick}
                handleDelete={deleteCart}
              />
            </div>
          )}
        </div>
        {shopData?.data?.data?.length > 20 && (
          <div className="mt-10">
            <Pagination
              align="center"
              current={page}
              pageSize={pageSize}
              total={shopData?.data?.totalRecords ?? 0}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <ConfirmModal />
    </>
  );
};

export default AllShops;
