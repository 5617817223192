import { useEffect, useState } from "react";
import shopLogo from "../../assets/images/Medicine.webp";
import ProductCard from "../../components/ProductCard";
import dummyImg from "../../assets/images/dummy.jpg";
import { Link, useLocation, useParams } from "react-router-dom";
import Logo from "../../components/Logo"
import useAxios from "../../hooks/useAxios";
import { useSelector } from "react-redux";
import { Pagination } from "antd";
import Sidebar from "../Landing/Sidebar";
import noProductFound from "../../assets/svg/no-product-found.svg";
import { fetchCategories, fetchShopData } from "../../services/products.service";
import { CloseIcon, Dropdown, FilterIcon } from "../../assets/cart/svg";
import { useTranslation } from "react-i18next";

let page = 1;
const pageSize = 30;

const ShopPanel = () => {
  const { id } = useParams();
  const { t } = useTranslation("ShopPanel")
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const shopInfo = location.state?.shop;
  const shopData = useSelector((state) => state?.products?.shopPanelData);
  const searchParam = useSelector((state) => state?.products?.searchParam);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(null);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isShopFetching, setIsShopFetching] = useState(false);
  const { makeRequest } = useAxios();

  const handleSidebarToggle = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };
  
  const handleShopData = () => {
    fetchShopData(
      makeRequest,
      id,
      debouncedSearchQuery,
      selectedCategory,
      selectedSubcategory,
      page,
      pageSize,
      setIsShopFetching
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      page = 1;
      setDebouncedSearchQuery(searchParam);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [searchParam]);

  const handleCloseModal = () => setModalOpen(false);

  const handlePageChange = (p) => {
    page = p;
    handleShopData();
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setExpandedCategories(
      expandedCategories === category?.id ? null : category?.id
    );
    setSelectedSubcategory(null);
  };

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setSelectedCategory(null);
  };

  const reset = () => {
    setSelectedCategory(null);
    setExpandedCategories(null);
    setSelectedSubcategory(null);
  };

  useEffect(() => {
    if (id) {
      handleShopData();
      fetchCategories(makeRequest, id, setCategoryData);
    }
  }, [id, debouncedSearchQuery, selectedCategory, selectedSubcategory]);

  return (
    <>
      <div className="sticky lg:top-14 top-0 z-40 gap-3 flex w-full items-center border-b border-gray-300 bg-white py-4 px-6 lg:hidden">
        <div className="relative w-16 h-16 mx-auto overflow-hidden bg-gray-200 border border-gray-100 rounded-lg shrink-0 mr-4 rtl:ml-4">
          <img
            alt="logo"
            loading="lazy"
            className="object-cover"
            src={shopInfo?.storeLogoUrl || shopLogo}
          />
        </div>
        <div className="w-full">
          <h3 className="text-base font-semibold">{shopInfo?.storeName}</h3>
          <button
            onClick={() => setModalOpen(true)}
            className="text-sm font-semibold transition text-primary hover:text-primary-hover"
          >
          {t("moreInfo")}
          </button>
        </div>
      </div>
      <div className="sticky z-20 flex h-14 items-center justify-between border-t border-b border-border-200 bg-white py-3 px-5 md:h-16 lg:px-6 lg:hidden top-[59px] lg:top-[63px]">
        <button
          onClick={handleSidebarToggle}
          className="flex gap-2 h-8 items-center rounded border border-border-200 bg-gray-100 bg-opacity-90 py-1 px-3 text-sm font-semibold text-heading transition-colors duration-200 hover:border-primary-hover hover:bg-primary hover:text-white focus:border-primary-hover focus:bg-primary focus:text-white focus:outline-0 md:h-10 md:py-1.5 md:px-4 md:text-base"
        >
          <FilterIcon/>
          {t("filter")}
        </button>
        <button
          onClick={reset}
          className="flex gap-2 h-8 items-center rounded border border-border-200 bg-gray-100 bg-opacity-90 py-1 px-3 text-sm font-semibold text-heading transition-colors duration-200 hover:border-primary-hover hover:bg-primary hover:text-white focus:border-primary-hover focus:bg-primary focus:text-white focus:outline-0 md:h-10 md:py-1.5 md:px-4 md:text-base"
        >
          {t("reset")}
        </button>
      </div>
      <div
        className={`fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-0 transition-opacity duration-300 ${
          isSideBarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {/* Overlay for background opacity */}
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <section
          className={`fixed top-0 left-0 z-50 h-full w-full max-w-md bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
            isSideBarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ width: "100%", maxWidth: "28rem" }}
        >
          <div className="flex h-full flex-col">
            <div className="fixed top-0 z-20 flex w-full max-w-md items-center justify-between border-b border-gray-200 border-opacity-75 bg-white p-5 px-5 md:px-6">
              <Link className="inline-flex w-24 md:w-auto" to="/">
                <span className="relative h-[2.125rem] w-32 flex items-center overflow-hidden md:w-[8.625rem]">
                  <Logo/>
                </span>
              </Link>
              <button
                onClick={() => setIsSideBarOpen(false)}
                className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 transition-all duration-200 hover:bg-primary hover:text-white focus:outline-0"
              >
                <span className="sr-only">close</span>
                <CloseIcon/>
              </button>
            </div>
            <div className="pt-20">
              <ul className="text-xs xl:py-8 px-5">
                {categoryData?.map((category) => (
                  <div  key={category.id}>
                    <li
                      className={`rounded-md py-1 duration-200 ${
                        selectedCategory?.id === category.id
                          ? "text-primary"
                          : ""
                      }`}
                      style={{ backgroundColor: "rgb(255, 255, 255)" }}
                     
                    >
                      <button
                        className="flex w-full justify-between gap-4 items-center py-2 font-semibold outline-none transition-all ease-in-expo focus:outline-0 focus:ring-0 text-left rtl:text-right text-sm"
                        onClick={() => {
                          // toggleDropdown(category.id);
                          handleCategoryClick(category);
                        }}
                      >
                        <div className="flex items-center ">
                          <span className="flex h-5 w-5 items-center justify-center mr-4">
                            {category.icon}
                          </span>
                          <span className="ml-1 text-left">
                            {category.categoryName}
                          </span>
                        </div>
                        <span className="ml-auto mr-4 transition-transform transform duration-300 ease-in-out">
                         <Dropdown/>
                        </span>
                      </button>
                    </li>
                    <li>
                      <ul
                        className="text-xs ml-1"
                        // className={`transition-all px-6 duration-300 ease-in-out overflow-hidden ${expandedCategories[category.id]
                        //     ? 'opacity-100 max-h-40'
                        //     : 'opacity-0 max-h-0'
                        //     }`}
                      >
                        {expandedCategories === category?.id &&
                          category.subCategories.length > 0 &&
                          category.subCategories.map((subcategory) => (
                            <li
                              className={`rounded-md py-1 ${
                                selectedSubcategory?.subCategoryName ===
                                subcategory?.subCategoryName
                                  ? "text-primary"
                                  : ""
                              }`}
                              style={{
                                backgroundColor: "rgb(255, 255, 255)",
                              }}
                              key={subcategory?.id}
                            >
                              <button
                                className="flex w-full items-center py-2 font-semibold outline-none transition-all focus:text-primary focus:outline-0 focus:ring-0 text-left rtl:text-right text-sm"
                                onClick={() =>
                                  handleSubcategoryClick(subcategory)
                                }
                              >
                                <span className="flex h-5 w-5 items-center justify-center mr-4">
                                  {/* {category.icon} */}
                                </span>
                                <span>{subcategory?.subCategoryName}</span>
                              </button>
                            </li>
                          ))}
                      </ul>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
      {/* Modal for mobile view */}
      {modalOpen && (
        <div className="fixed top-10 inset-0 z-50 flex items-center md:p-5 justify-center bg-black bg-opacity-10">
          {/* Overlay for background opacity */}
          <div
            className="absolute inset-0 bg-black opacity-20"
            onClick={handleCloseModal}
          ></div>
          <div className="relative h-screen sm:h-[95vh] w-[100%] max-w-[640px] z-30 flex flex-col bg-white sm:rounded-md">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-red-500 transition-all"
              onClick={handleCloseModal}
            >
              <i className="ri-close-line text-xl"></i>
            </button>
            <div className="flex flex-col w-full p-6 border-b border-t border-gray-200">
              <div className="flex items-center justify-start mb-4">
                <div className="flex items-center justify-center w-24 h-24 border border-gray-200 rounded-full shrink-0">
                  <div className="relative w-[calc(100%-8px)] h-[calc(100%-8px)] overflow-hidden bg-gray-200 rounded-full">
                    <img
                      alt="logo"
                      loading="lazy"
                      className="object-cover"
                      src={shopInfo?.storeLogoUrl || shopLogo}
                    />
                  </div>
                </div>
                <div className="pl-2.5">
                  <div className="text-sm text-gray-400">Since 2023</div>
                  <h3 className="mb-2 overflow-hidden text-lg font-semibold truncate ">
                    {shopInfo?.storeName}
                  </h3>
                  <div className="flex flex-wrap text-sm rounded gap-x-4">
                    <div className="flex justify-center gap-1.5 text-gray-500">
                      <div className="font-medium">
                        {shopData?.data?.totalRecords}
                      </div>
                      {t("products")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="grid grid-cols-[repeat(auto-fill,minmax(70px,1fr))] text-sm gap-1.5 p-6">
              <a
                className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                href="/shops/medicine/offers"
              >
                <img src={couponIcon} alt="" />
                <span className="pt-2 text-sm">Coupons</span>
              </a>
              <a
                className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                href="/shops/medicine/contact"
              >
                <img src={contactIcon} alt="" />
                <span className="pt-2 text-sm">Contact</span>
              </a>
              <a
                href="test.com"
                target="_blank"
                className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                rel="noreferrer"
              >
                <img src={websiteIcon} alt="" />
                <span className="pt-2 text-sm"> Website</span>
              </a>
              <a
                className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                href="/shops/medicine/terms"
              >
                <img src={termsIcon} alt="" />
                <span className="pt-2 text-sm">Terms</span>
              </a>
              <a
                className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                href="/shops/medicine/faqs"
              >
                <img src={faqIcon} alt="" />
                <span className="pt-2 text-sm">FAQs</span>
              </a>
            </div> */}

            <div className="p-6 border-t border-gray-200">
              <div className="flex flex-col mb-5 last:mb-0">
                <span className="mb-1.5 text-sm font-semibold">Address</span>
                <p className="text-sm">
                  {shopInfo?.address || "Not Available"}
                </p>
              </div>
              <div className="flex flex-col mb-5 last:mb-0">
                <span className="mb-1.5 text-sm font-semibold">Phone</span>
                <p className="text-sm">{shopInfo?.user?.mobileNumber}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* sidebar start */}
      <div className="flex p-5 sm:p-8 bg-gray-100">
        <aside className="hidden h-full w-full bg-white md:rounded lg:block lg:w-80 2xl:w-96 sticky lg:top-24">
          <div className="max-h-full overflow-hidden">
            <div className="flex flex-col h-full">
              <Sidebar
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoryData={categoryData}
                isSideBarOpen={isSideBarOpen}
                setIsSideBarOpen={setIsSideBarOpen}
                handleCategoryClick={handleCategoryClick} 
                handleSubcategoryClick={handleSubcategoryClick}
                expandedCategories={expandedCategories}
                setExpandedCategories={setExpandedCategories}
                reset={reset}
              />
              <div className="flex flex-col w-full p-6 border-b border-t border-gray-200">
                <div className="flex items-center justify-start mb-4">
                  <div className="flex items-center justify-center w-20 h-20 border border-gray-200 rounded-full shrink-0">
                    <div className="relative w-[calc(100%-8px)] h-[calc(100%-8px)] overflow-hidden bg-gray-200 rounded-full">
                      <img
                        alt="logo"
                        loading="lazy"
                        className="object-cover w-full h-full"
                        src={shopInfo?.storeLogoUrl || shopLogo}
                      />
                    </div>
                  </div>
                  <div className="pl-2.5 ">
                    <div className="text-sm text-gray-400">Since 2023</div>
                    <h3 className="mb-2 overflow-hidden text-sm font-semibold truncate">
                      {shopInfo?.storeName}
                    </h3>
                    <div className="flex flex-wrap text-sm rounded gap-x-4">
                      <div className="flex justify-center gap-1 text-gray-500">
                        <div className="font-medium">
                          {shopData?.data?.totalRecords}
                        </div>{" "}
                        {t("products")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="grid grid-cols-[repeat(auto-fill,minmax(70px,1fr))] text-sm gap-1.5 p-6">
                <a
                  className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                  href="/shops/medicine/offers"
                >
                  <img src={couponIcon} alt="" />
                  <span className="pt-2 text-sm">Coupons</span>
                </a>
                <a
                  className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                  href="/shops/medicine/contact"
                >
                  <img src={contactIcon} alt="" />
                  <span className="pt-2 text-sm">Contact</span>
                </a>
                <a
                  href="test.com"
                  target="_blank"
                  className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                  rel="noreferrer"
                >
                  <img src={websiteIcon} alt="" />
                  <span className="pt-2 text-sm"> Website</span>
                </a>
                <a
                  href="test.com"
                  target="_blank"
                  className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                  rel="noreferrer"
                >
                  <img src={termsIcon} alt="" />
                  <span className="pt-2 text-sm"> Terms</span>
                </a>
                <a
                  href="test.com"
                  target="_blank"
                  className="flex flex-col items-center justify-center p-2 pt-3.5 pb-3 text-gray-500 rounded bg-gray-50 group hover:text-primary hover:bg-primary/10 transition-all"
                  rel="noreferrer"
                >
                  <img src={faqIcon} alt="" />
                  <span className="pt-2 text-sm"> FAQs</span>
                </a>
              </div> */}
              <div className="p-6 border-t border-gray-200">
                <div className="flex flex-col mb-5 last:mb-0">
                  <span className="mb-1.5 text-sm font-semibold">{t("Address")}</span>
                  <p className="text-sm ">
                    {shopInfo?.address || "Not Available"}
                  </p>
                </div>
                <div className="flex flex-col mb-5 last:mb-0">
                  <span className="mb-1.5 text-sm font-semibold">{t("Phone")}</span>
                  <p className="text-sm">{shopInfo?.user?.mobileNumber}</p>
                </div>
              </div>
            </div>
          </div>
        </aside>

        {/* Content area */}
        <div className="flex flex-col w-full pb-12 lg:p-0 lg:pl-8">
          {/* <div className="relative w-full h-full overflow-hidden rounded">
            <img
              alt="heading"
              loading="lazy"
              className="w-full h-full"
              src={shopBanner}
            />
          </div> */}
          <div className="w-full">
            <div
              className="grid grid-cols-2 gap-3
                    sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] 
                    md:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]
                  "
            >
              {!isShopFetching && shopData?.data?.data?.length ? shopData?.data?.data?.map((product) => (
                <ProductCard
                  key={product.id}
                  id={product.id}
                  title={product.b || "No Title"}
                  price={product.h}
                  storeId={product.storeId}
                  image={product.imageUrl[0] || dummyImg} // Fallback image if none provided
                  del={null}
                />
              )) : null}
            </div>
            {!shopData?.data?.data?.length ? (
              <div className="w-full min-h-full px-4">
                <div className="flex flex-col items-center w-7/12 mx-auto">
                  <div className="w-full h-full flex items-center justify-center">
                    <img
                      alt="Sorry, No Product Found :("
                      loading="lazy"
                      width="600"
                      height="453"
                      decoding="async"
                      className="w-full h-full object-contain"
                      src={noProductFound}
                      style={{ color: "transparent" }}
                    />
                  </div>
                  <h3 className="w-full text-center text-xl font-semibold text-body my-7">
                    {t("Sorry, No Product Found")} :{"("}
                  </h3>
                </div>
              </div>
            ) : null}
            {shopData?.data?.totalRecords > 30 && (
              <div className="mt-10">
                <Pagination
                  align="center"
                  current={page}
                  pageSize={pageSize}
                  total={shopData?.data?.totalRecords ?? 0}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopPanel;
