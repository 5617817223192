import React, { useState, useRef } from 'react';
import SectionHeader from '../../components/SectionHeader';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        { question: "What is your return policy?", answer: "We have a flexible return policy. If you're not satisfied with your purchase, you can return most items within 30 days for a full refund or exchange. Please review our Return Policy for more details." },
        { question: "Can I track my order?", answer: "Yes, you can track your order through our tracking system. Once your order is shipped, you'll receive an email with the tracking details." },
        { question: "How long will it take to receive my order?", answer: "It typically takes 3-5 business days to receive your order. However, the shipping time may vary depending on your location." },
        { question: 'What payment methods do you accept?', answer: 'We accept a variety of payment methods, including credit cards (Visa, MasterCard, American Express), PayPal, and more. You can choose your preferred payment option during the checkout process.' },
        { question: 'How can I place an order?', answer: 'To place an order, simply browse our online store, add the items you want to your cart, and proceed to checkout. Follow the prompts to enter your shipping information and payment details to complete your purchase.' }
    ];

    return (
        <section className="w-full min-h-screen pb-16 mx-auto max-w-1920 bg-white lg:pb-10 xl:pb-14">
            <SectionHeader title='FAQ' breadcrumbText='FAQ' breadcrumbLink='/' />
            <div className="w-full max-w-screen-lg px-4 py-10 mx-auto">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`border border-solid border-border-200 bg-white rounded mb-2.5 transition-all duration-300 hover:border-2 shadow-sm ${activeIndex === index ? 'border-2' : ''}`}
                    >
                        <header
                            className="py-4 px-5 rounded cursor-pointer flex items-center justify-between transition-colors"
                            onClick={() => toggleFAQ(index)}
                        >
                            <h2 className="text-sm md:text-base font-semibold leading-relaxed">
                                {faq.question}
                            </h2>
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className={`flex-shrink-0 stroke-2 transform transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`}
                                width="18"
                                height="18"
                            >
                                {activeIndex === index ? (
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"></path> // '-' icon
                                ) : (
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path> // '+' icon
                                )}
                            </svg>
                        </header>
                        <div
                            ref={(el) => (contentRefs.current[index] = el)}
                            className={`overflow-hidden transition-all duration-300 ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'}`}
                            style={{
                                maxHeight: activeIndex === index
                                    ? contentRefs.current[index]?.scrollHeight + "px"
                                    : "0px",
                            }}
                        >
                            <div className="md:pt-1 pb-4 px-5 leading-7 text-sm md:text-base md:leading-loose text-body-dark">
                                {faq.answer}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default FAQ;
