import axios from 'axios';
import store from '../store';
import { logout } from '../redux/reducers/authSlice';

// Create a new Axios instance
const instance = axios.create({
 baseURL: process.env.REACT_APP_SERVER_URL, //Your base URL
  timeout: 180000, // Adjust timeout as needed
});

// Add an interceptor to include token and extra headers
instance.interceptors.request.use(function (config) {
  // Add token to headers if it exists
  const { user } = store.getState().authSlice;
  const token = user?.data?.accessToken; // Example of retrieving token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Device-ID'] = localStorage.getItem('$it_d');
  }

  // Add extra headers if provided
  if (config.extraHeaders) {
    config.headers = { ...config.headers, ...config.extraHeaders };
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(
  function (response) {
    // If the response is successful, return it
    return response;
  },
  function (error) {
    // Check if the error response has a 401 status code
    if (error.response && error.response.status === 401) {
      // Optionally clear Redux state or notify user
      store.dispatch(logout()); // Assuming you have a logout action
    }

    // Reject the promise with the error object
    return Promise.reject(error);
  }
);

export default instance;