import React from 'react'
import SectionHeader from '../../components/SectionHeader'
import { Link } from 'react-router-dom'

const section = [
    {
        id: 'vendor',
        title: 'Vendor Return Policy',
        content: 'Our vendor return policy ensures that you can return products within 30 days of purchase if they are damaged or not as described.'
    },
    {
        id: 'customer',
        title: 'Customer Return Policy',
        content: 'Our customer return policy allows you to return products within 14 days of purchase for a full refund, no questions asked.'
    },
    {
        id: 'electronics',
        title: 'Electronics Return Policy',
        content: 'For electronics, our return policy extends to 60 days. We stand by the quality of our electronic products.'
    },
    {
        id: 'furniture',
        title: 'Furniture Return Policy',
        content: "Our furniture return policy allows you to return furniture within 7 days if it doesn't meet your expectations. Customer satisfaction is our priority."
    },
    {
        id: 'custom_orders',
        title: 'Custom Orders Policy',
        content: 'Please note that custom orders are not eligible for returns or refunds. We craft custom items to your specifications.'
    }

]
const CustomerPolicy = () => {
    return (
        <section className="mx-auto min-h-screen bg-white w-full max-w-1920 pb-8 lg:pb-10 xl:pb-14">
            <SectionHeader title="Customer Refund Policy" breadcrumbText="Customer Refund Policy" breadcrumbLink="/" />
            <div className="mx-auto w-full max-w-screen-lg px-4 py-10">
                <div className="flex flex-col md:flex-row">
                    <nav className="mb-8 hidden md:mb-0 md:block md:w-60 lg:w-72 xl:w-80">
                        <ol className="sticky z-10 md:top-28 lg:top-24">
                            {
                                section.map((section) => (
                                    <li key={section.id}>
                                        <a href={`#${section.id}`} className="my-3 inline-flex cursor-pointer pl-4">{section.title}</a>
                                    </li>
                                ))
                            }
                        </ol>
                    </nav>
                    <div className="md:w-9/12 md:pb-10 md:pl-8 rtl:md:pr-8">
                        {
                            section.map((section) => (
                                <div key={section.id} id={section.id} className="mb-7 md:mb-10">
                                    <h2 className="mb-4 text-lg font-bold  md:text-xl lg:text-2xl">{section.title}</h2>
                                    <div className="mt-15 leading-loose">{section.content}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerPolicy
