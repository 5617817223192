import {
  ADD_USER_ADDRESS,
  CREATE_ORDER,
  DELETE_ADDRESS,
  EDIT_USER_ADDRESS,
  FETCH_USER_ADDRESS,
} from "../contstants/apis";

export const fetchUserAddress = async (makeRequest, id, cb, setAddress) => {
  await makeRequest({
    url : `${FETCH_USER_ADDRESS}/${id}`,
    reduxAction : cb,
    onSuccessCallback : (res) => {
      if (res?.code === 200) {
        setAddress("");
      }
    },
   isToastVisible : false
});
};

export const deleteAddress = async (makeRequest, id, cb) => {
  await makeRequest({
   url :  `${DELETE_ADDRESS}/${id}`,
   method :  "DELETE",
    onSuccessCallback : (res) => {
      if (res?.code === 200) {
        cb();
      }
    },
    isToastVisible : false
});
};

export const createOrder = async (
  makeRequest,
  formData,
  dispatch,
  navigate,
  cb
) => {
  await makeRequest({
    url : `${CREATE_ORDER}`,
    method : "POST",
    data : formData,
    reduxAction : () => {
      dispatch(cb(null));
      navigate("/order-detail");
    },
    isToastVisible : true
});
};

export const addAddress = async (
  makeRequest,
  params,
  type,
  cb
) => {
  await makeRequest({
   url :  `${ADD_USER_ADDRESS}`,
    method : "POST",
   data :  params,
    onSuccessCallback : (res) => {
      if (res?.code === 200) {
       cb(type, res)
      }
    },
    isToastVisible : false
});
};

export const handleEditAddressFuc = async (makeRequest, params, id, cb) =>{
  await makeRequest({
    url : `${EDIT_USER_ADDRESS}/${id}`,
    method : "PUT",
    data : params,
    onSuccessCallback : (res) => {
      if (res?.code === 200) {
        cb()
      }
    },
     isToastVisible : false
});
}