import { combineReducers } from 'redux';
import products from './products';
import authSlice from './authSlice';


const appReducer = combineReducers({
    products: products,
    authSlice: authSlice
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        // eslint-disable-next-line no-param-reassign
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer