import React from "react";

const CartConfirmModal = ({ handleCurrent, handlePrevious }) => {
  return (
    <div className="m-auto z-50 w-full max-w-sm rounded-md bg-white border p-4 pb-6 sm:w-[24rem] md:rounded-xl">
      <div className="h-full w-full text-center">
        <div className="flex h-full flex-col justify-between">
          {/* <span className="m-auto mt-4 text-primary">
            <svg
              className="h-12 w-12"
              fill="currentColor"
              viewBox="0 0 1792 1792"
            >
              <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"></path>
            </svg>
          </span> */}
          <p className="mt-4 text-xl font-bold text-heading">It seems there are some items in your cart from another store!</p>
          <p className="px-6 py-2 leading-relaxed text-body-dark dark:text-muted">
            Press "Current" to keep Current Cart or Press "Previous" to keep Previous Cart.
          </p>
          <div className="mt-8 flex w-full items-center justify-between space-x-4 rtl:space-x-reverse">
            <div className="w-1/2">
              <button
                onClick={handleCurrent}
                className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-primary-700 px-5 py-0 h-12 w-full rounded bg-primary py-2 px-4 text-center text-base font-semibold text-white shadow-md transition duration-200 ease-in hover:bg-primary-hover focus:bg-primary-hover focus:outline-none"
              >
                Current
              </button>
            </div>
            <div className="w-1/2">
              <button onClick={handlePrevious} className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-primary-700 px-5 py-0 h-12 w-full rounded bg-red-600 py-2 px-4 text-center text-base font-semibold text-white shadow-md transition duration-200 ease-in hover:bg-red-700 focus:bg-red-700 focus:outline-0">
                Previous
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartConfirmModal;
