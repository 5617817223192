import { useState } from "react";
import productImage from "../assets/images/dummy.jpg";
import ProductDetail from "./ProductDetail";
import useAxios from "../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { setQuantity } from "../redux/reducers/products";
import plusIcon from "../assets/svg/plus-icons.svg"
import minusIcon from "../assets/svg/minus-icon.svg"
import {
  fetchCartItems,
  fetchTempCartItems,
  handleDeleteCartItems,
  updateCart,
} from "../services/products.service";
import { CartAddIcon } from "../assets/cart/svg";

const ProductCard = (productData) => {
  const { loading } = useSelector((state) => state.authSlice);
  const { id, title, price, image, percentage, storeId } = productData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cartData, tempCartData } = useSelector((state) => state.products);
  const { makeRequest } = useAxios();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const uuid = localStorage?.getItem("uuid");

  const getProductQuantity = (productId) => {
    const cartItem = (isLoggedIn ? cartData : tempCartData)?.data?.items.find(
      (item) => item.productId === productId
    );
    return cartItem ? cartItem?.quantity : 0;
  };

  const updateSuccessCb = () => {
    if (isLoggedIn) {
      fetchCartItems(makeRequest, dispatch);
    } else {
      fetchTempCartItems(makeRequest, dispatch, uuid);
    }
  };

  const handleUpdateCart = (newQuantity) => {
    updateCart(
      makeRequest,
      isLoggedIn,
      newQuantity,
      id,
      storeId,
      updateSuccessCb,
      uuid
    );
  };

  const handleAddToCart = () => {
    const initialQuantity = 1;
    dispatch(setQuantity(initialQuantity));
    handleUpdateCart(initialQuantity);
  };

  const handleIncreaseQuantity = (qty) => {
    const quantity = qty + 1;
    handleUpdateCart(quantity);
  };

  const handleDecreaseQuantity = (qty) => {
    const quantity = qty - 1;
    if (quantity > 0) {
      handleUpdateCart(quantity);
    } else {
      handleDeleteCartItems(
        makeRequest,
        dispatch,
        isLoggedIn,
        cartData?.data?.id,
        tempCartData?.data?.id,
        id,
        uuid
      );
    }
  };

  return (
    <>
      <article className="p-3 overflow-hidden rounded border border-border-200 bg-white transition-shadow duration-200 hover:shadow-sm">
        <div className="w-full flex justify-end">
          <div className="top-3 rounded-full w-fit bg-[#EAB308] px-1.5 text-xs font-semibold text-white">
            {percentage}
          </div>
        </div>
        <div
          onClick={() => setIsModalOpen(true)}
          className="relative flex w-auto items-center justify-center"
        >
          <img
            alt={title}
            className="block object-contain h-40 sm:h-60 "
            src={image}
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = productImage;
            }}
          />
        </div>
        <header className="sm:p-3">
          <h3 className="mb-2 text-sm font-semibold truncate">{title}</h3>
          {/* <div className="flex items-start sm:items-center justify-start sm:justify-between mt-7 flex-col sm:flex-row gap-2 "> */}
          <div className="flex items-center justify-between mt-7 gap-2 ">
            <span className="text-sm font-semibold text-primary">${price}</span>
            {getProductQuantity(id) === 0 ? (
              <button
              disabled={loading}
                onClick={handleAddToCart}
                className="flex h-10 items-center gap-2 disabled:cursor-not-allowed justify-center rounded-full border-2 border-border-100 bg-white px-4 py-2 text-sm font-semibold text-primary hover:bg-primary hover:text-white"
              >
                <CartAddIcon />
                Cart
              </button>
            ) : (
              <div className="flex bg-primary text-white rounded-full items-center py-2 h-10">
                <button
                disabled={loading}
                  onClick={() => handleDecreaseQuantity(getProductQuantity(id))}
                  className="px-3 py-1 sm:text-xl text-sm leading-normal font-semibold disabled:cursor-not-allowed"
                >
                 <img src={minusIcon} className="w-3 h-3"/>
                </button>
                <span className="px-0 sm:px-0 text-sm font-bold leading-normal">
                  {getProductQuantity(id)}
                </span>
                <button
                disabled={loading}
                  onClick={() => handleIncreaseQuantity(getProductQuantity(id))}
                  className="px-3 py-1 sm:text-xl text-sm disabled:cursor-not-allowed leading-normal font-semibold"
                >
                  {/* <span class="sr-only">plus</span> */}
                  <img src={plusIcon} className="w-3 h-3"/>
                </button>
              </div>
            )}
          </div>
        </header>
      </article>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="absolute inset-0"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <ProductDetail
            handleDecreaseQuantity={handleDecreaseQuantity}
            handleIncreaseQuantity={handleIncreaseQuantity}
            productId={id}
            storeId={storeId}
            onCancel={() => setIsModalOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default ProductCard;
