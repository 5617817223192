import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dummyImg from "../../assets/images/dummy.jpg";
import { Table } from "antd";
import useAxios from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { setOrderData } from "../../redux/reducers/products";
import { FETCH_ORDERS } from "../../contstants/apis";
import axios from "axios";
import { HomeIcon } from "../../assets/cart/svg";
import { useTranslation } from "react-i18next";

const OrderStatus = () => {
  const dispatch = useDispatch();
  const { makeRequest } = useAxios();
  const [visible, setVisible] = useState(null);
  const { orderData } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.authSlice);
  const [addresses, setAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const userId = user?.data?.data?.id;
  const { t } = useTranslation("OrderDetail");

  const fetchOrderDetails = async () => {
    makeRequest({
      url: `${FETCH_ORDERS}/${userId}`,
      onSuccessCallback: (res) => {
        dispatch(setOrderData(res));
      },
      isToastVisible: false,
    });
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const columns = [
    {
      title: "Item",
      dataIndex: "productName",
      key: "productName",
      render: (text, record) => {
        return (
          <div className="flex items-center">
            <div className="relative flex h-16 w-16 shrink-0 overflow-hidden rounded">
              <img
                alt={record.productName}
                loading="lazy"
                className="h-full w-full object-contain"
                src={record.productImage || dummyImg}
              />
            </div>
            <div className="flex flex-col overflow-hidden ml-4 rtl:mr-4">
              <p className="inline-block overflow-hidden truncate text-sm font-semibold transition-colors hover:text-primary hover:underline">
                {record.productName}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => `$${text}`,
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text) => `$${text}`,
    },
  ];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "placed":
        return { bg: "bg-yellow-100", text: "text-yellow-600" };
      case "shipped":
        return { bg: "bg-blue-100", text: "text-blue-600" };
      case "completed":
        return { bg: "bg-green-100", text: "text-green-600" };
      case "cancelled":
        return { bg: "bg-red-100", text: "text-red-600" };
      default:
        return { bg: "bg-gray-100", text: "text-gray-600" };
    }
  };

  const handleOrderVisible = (id) => {
    if (visible === id) {
      setVisible(null);
    } else {
      setVisible(id);
    }
  };

  const fetchAddressById = async (id) => {

    try {
      const response = await axios.get(
        `https://api.pickdeliver.app/api/ecom/order/address/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.data?.accessToken}`,
            // Add any other custom headers as needed
          },
        }
      );

      return response?.data?.data?.address; // Adjust based on your API response structure
    } catch (error) {
      console.error(`Error fetching address for ID ${id}:`, error);
      return null; // Return null in case of error
    }
  };

  const fetchAllAddresses = async () => {
    const addressPromises = (
      orderData?.data?.length ? orderData?.data : []
    )?.map((item) => fetchAddressById(item.shippingAddress));
    const billingAddressPromises = (
      orderData?.data?.length ? orderData?.data : []
    )?.map((item) => fetchAddressById(item.billingAddress));
    const fetchedAddresses = await Promise.all(addressPromises);
    const fetchedBillingAddresses = await Promise.all(billingAddressPromises);
    setAddresses(fetchedAddresses);
    setBillingAddresses(fetchedBillingAddresses);
  };

  useEffect(() => {
    fetchAllAddresses();
  }, [orderData]);

  return (
    <div className="p-4 sm:p-8">
      <div className="mx-auto w-full max-w-screen-lg">
        <div className="mb-5">
          <Link
            className="inline-flex items-center gap-2 text-sm font-semibold text-primary hover:text-primary-hover"
            to="/"
          >
            <HomeIcon />
            {t("backHome")}
          </Link>
        </div>
        {(orderData?.data?.length ? orderData?.data : [])?.map(
          (order, index) => {
            return (
              <>
                <div
                  key={index}
                  onClick={() => handleOrderVisible(order?.id)}
                  className={`relative overflow-hidden rounded border shadow-sm ${
                    index > 0 ? "mt-5" : "mt-0"
                  }`}
                >
                  <div className="bg-[#F7F8FA] lg:px-11 lg:py-5 p-6">
                    <div className="flex flex-col flex-wrap items-center justify-between mb-0 text-base font-bold gap-x-8 sm:flex-row lg:flex-nowrap">
                      <div className="order-2 grid w-full grid-cols-1 gap-6 xs:flex-nowrap sm:order-1 max-w-full basis-full justify-between md:grid-cols-2">
                        <div className="flex items-center gap-3">
                          <span className="block text-xs shrink-0 grow-0 basis-auto xs:text-base lg:inline-block">
                            {t("orderStatus")} :
                          </span>
                          <div className="w-full lg:w-auto">
                            <span
                              className={`px-3 py-1 rounded-full min-h-[2rem] ${
                                getStatusColor(order?.orderStatus).bg
                              } ${
                                getStatusColor(order?.orderStatus).text
                              } items-center justify-center text-[8px] !leading-none xs:text-sm inline-flex`}
                            >
                              {order?.orderStatus}
                            </span>
                          </div>
                        </div>
                        {/* <div className="flex items-center gap-3 md:ml-auto">
                          <span className="block text-xs shrink-0 grow-0 basis-auto xs:text-base lg:inline-block">
                            {t("paymentStatus")} :
                          </span>
                          <div className="w-full lg:w-auto">
                            <span className="px-3 py-1 rounded-full text-white bg-primary bg-opacity-[.15] !text-primary min-h-[2rem] items-center justify-center truncate whitespace-nowrap text-[9px] !leading-none xs:text-sm inline-flex">
                              {order?.paymentStatus}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-6 sm:p-8 lg:p-12">
                    <div
                      className={` grid gap-4 sm:grid-cols-2 ${
                        visible === order?.id ? "mb-6 md:mb-12" : ""
                      } lg:grid-cols-3`}
                    >
                      <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                        <h3 className="mb-2 text-sm font-semibold">
                          {t("orderNumber")}
                        </h3>
                        <p className="text-sm ">{order?.orderNumber}</p>
                      </div>
                      <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                        <h3 className="mb-2 text-sm font-semibold">
                          {t("date")}
                        </h3>
                        <p className="text-sm">
                          {formatDate(order?.createdOn)}
                        </p>
                      </div>

                      <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                        <h3 className="mb-2 text-sm font-semibold">
                          {t("total")}
                        </h3>
                        <p className="text-sm">${order?.totalOrderValue}</p>
                      </div>
                      {/* <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                        <h3 className="mb-2 text-sm font-semibold">
                          {t("paymentMethod")}
                        </h3>
                        <p className="text-sm ">{order?.paymentStatus}</p>
                      </div> */}
                    </div>
                    {visible === order?.id && (
                      <div
                        className={`transition-all duration-500 ease-in-out overflow-hidden ${
                          visible
                            ? "opacity-100 max-h-screen"
                            : "opacity-0 max-h-0"
                        }`}
                      >
                        {/* <ProgressBar /> */}
                        <div className="flex flex-col lg:flex-row">
                          <div className="mb-12 w-full lg:mb-0 lg:w-1/2 lg:pr-3 rtl:lg:pl-3">
                            <h2 className="mb-6 text-xl font-bold">
                              {t("totalAmount")}
                            </h2>
                            <div>
                              <p className="mt-5 flex ">
                                <strong className="w-5/12 text-sm font-semibold sm:w-4/12">
                                  {t("subTotal")} :
                                </strong>
                                <span className="w-7/12 text-sm pl-4 rtl:pr-4 sm:w-8/12 ">
                                  {order?.subTotal}
                                </span>
                              </p>

                              <p className="mt-5 flex ">
                                <strong className="w-5/12 text-sm font-semibold sm:w-4/12">
                                  {t("tax")} :
                                </strong>
                                <span className="w-7/12 text-sm pl-4 rtl:pr-4 sm:w-8/12 ">
                                  {order?.totalTax}
                                </span>
                              </p>

                              <p className="mt-5 flex ">
                                <strong className="w-5/12 text-sm font-semibold sm:w-4/12">
                                  {t("total")} :
                                </strong>
                                <span className="w-7/12 text-sm pl-4 rtl:pr-4 sm:w-8/12">
                                  ${order?.totalOrderValue}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="w-full lg:w-1/2 lg:pl-3 rtl:lg:pr-3">
                            <h2 className="mb-6 text-xl font-bold">
                              {t("orderDetails")}
                            </h2>
                            <div>
                              <p className="mt-5 flex ">
                                <strong className="w-4/12 text-sm font-semibold">
                                  {t("name")} :
                                </strong>
                                <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                                  {order?.customerFullName}
                                </span>
                              </p>
                              <p className="mt-5 flex ">
                                <strong className="w-4/12 text-sm font-semibold">
                                  {t("contactNumber")} :
                                </strong>
                                <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                                  {order?.customerContactNoPrimary}
                                </span>
                              </p>
                              <p className="mt-5 flex">
                                <strong className="w-4/12 text-sm font-semibold">
                                  {t("totalItem")} :
                                </strong>
                                <span className="w-8/12 text-sm pl-4 rtl:pr-4">
                                  {order?.items?.length || 0} Item
                                </span>
                              </p>

                              <p className="mt-5 flex ">
                                <strong className="w-4/12 text-sm font-semibold">
                                  {t("deliveryItem")} :
                                </strong>
                                <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                                  {order?.deliverySchedule ?? "-"}
                                </span>
                              </p>
                              <p className="mt-5 flex ">
                                <strong className="w-4/12 text-sm font-semibold">
                                  {t("shippingAddress")} :
                                </strong>
                                <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                                  {order?.shippingAddress}
                                </span>
                              </p>
                              <p className="mt-5 flex ">
                                <strong className="w-4/12 text-sm font-semibold">
                                  {t("billingAddress")} :
                                </strong>
                                <span className="w-8/12 text-sm pl-4 rtl:pr-4">
                                  {order?.billingAddress}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-12">
                          <Table
                            scroll={{ x: "max-content" }}
                            columns={columns}
                            pagination={false}
                            dataSource={order?.items}
                            className="rounded bg-white shadow"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* {order?.items?.length > 1 ? ( */}
                <div className="border-b-2 mt-4"></div>
                {/* ) : null} */}
              </>
            );
          }
        )}
      </div>
    </div>
  );
};

export default OrderStatus;
