import React, { useEffect, useState } from "react";
import { image } from "../../assets";
import ServiceCard from "../../components/ServiceComponent";
import ProductCard from "../../components/ProductCard";
import productImg from "../../assets/apple-product.png";
import homeBanner from "../../assets/landingBanner.png";
import CouponCardSlider from "../../components/CouponCardSlider";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/useAxios";
import Sidebar from "./Sidebar";

const HomePage = () => {
  const { t } = useTranslation("LandingPage");
  const { makeRequest } = useAxios();
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const serviceCards = [
    {
      title: "Express Delivery",
      description: "With selected items",
      imageSrc: image.truck,
      bgColor: "blue",
    },
    {
      title: "Cash on Delivery",
      description: "With selected items",
      imageSrc: image.hand,
      bgColor: "green",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "pink",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "blue",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "green",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "pink",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "blue",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "green",
    },
    {
      title: "Gift Voucher",
      description: "With personal care item",
      imageSrc: image.gift,
      bgColor: "pink",
    },
  ];


  const fetchProducts = async () => {
    const data = selectedCategory?.categoryName ? { category: selectedCategory?.categoryName } : { subCategory: selectedSubcategory?.subCategoryName };

    makeRequest(
      // `http://localhost:8089/api/ecom/public/products/dashboard?page=0&size=30`,
      `https://api.pickdeliver.app/api/ecom/public/products/dashboard?page=0&size=30`,
      "POST",
      data,
      { "content-type": "application/json" },
      (res) => {
        setProductData(res.data.data[0]?.products);
      },
      null,
      null,
      false
    );
  };
  const fetchCategories = async () => {
    makeRequest(
      // `http://localhost:8089/api/ecom/public/category`,
      `https://api.pickdeliver.app/api/ecom/public/category`,
      "GET",
      null,
      { "content-type": "application/json" },
      (res) => {
        setCategoryData(res.data);
      },
      null,
      null,
      false
    );
  };
  useEffect(() => {
    if (categoryData) {
      fetchCategories();
    }
    fetchProducts();
  }, [selectedCategory, selectedSubcategory]);


  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsToShow = 4;

  const handleNext = () => {
    if (currentIndex < serviceCards.length - cardsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="flex flex-col items-center bg-gray-100">
      <div className="hidden lg:flex gap-5 w-full justify-end">
        <div
          style={{
            // backgroundImage: `url(${homeBanner})`,
            backgroundImage: `url(${homeBanner})`,
            // backgroundImage: `url('https://pickbazar-react-rest.vercel.app/_next/image?url=https%3A%2F%2Fpickbazarlaravel.s3.ap-southeast-1.amazonaws.com%2F904%2Fgrocery.png&w=1920&q=75')`,
            minHeight: "100vh",
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
          className="flex flex-col w-screen h-screen justify-center items-center"
        >
          <div className="flex flex-col items-center mb-4 w-3/4 ">
            {" "}
            {/* Added flex-col and mb-4 for spacing */}
            <h1 className="text-5xl font-bold text-center">
              {t("landingHeaderTitle")}
            </h1>{" "}
            {/* Title */}
            <p className="text-lg text-gray-600 my-6 text-center">
              {t("landingHeaderDesc")}
            </p>
            {/* <div className="flex items-center w-full sm:-w-7/12">
              {" "} */}
            {/* Added mt-2 for spacing */}
            {/* <input
                type="text"
                placeholder="Search your products from here"
                className="flex-1 p-2 border-0 rounded-l-lg h-12 focus:outline-0"
              /> */}
            {/* <button className="bg-[#009F7F] text-white p-2 h-12 rounded-r-lg px-5">
                {t("btnSearchText")}
              </button>{" "} */}
            {/* Positioned to the right */}
            {/* </div> */}
            <div className="w-full max-w-3xl">
              <form className="w-full">
                <div className="relative flex rounded md:rounded-lg h-14 shadow-xl">
                  <label for="search" className="sr-only">
                    search
                  </label>
                  <input
                    id="search"
                    type="text"
                    autocomplete="off"
                    className="search item-center flex h-full w-full appearance-none overflow-hidden truncate rounded-lg text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:outline-0 focus:ring-0 bg-white ltr:pl-6 rtl:pr-6 ltr:pr-14 rtl:pl-14 ltr:rounded-tr-none rtl:rounded-tl-none ltr:rounded-br-none rtl:rounded-bl-none  border ltr:border-r-0 rtl:border-l-0 border-transparent focus:border-primary"
                    name="search"
                    placeholder="Search your products from here"
                  />
                  <button className="flex absolute top-0 right-0 h-full min-w-7 lg:min-w-[143px] items-center justify-center rounded-lg bg-primary lg:px-8 px-3 font-semibold text-white transition-colors duration-200 hover:bg-primary-hover focus:bg-primary-hover focus:outline-0 rounded-tl-none rounded-bl-none rtl:rounded-tr-none rtl:rounded-br-none">
                    <svg viewBox="0 0 17.048 18" className="h-4 w-4 mr-2.5">
                      <path
                        d="M380.321,383.992l3.225,3.218c.167.167.341.329.5.506a.894.894,0,1,1-1.286,1.238c-1.087-1.067-2.179-2.131-3.227-3.236a.924.924,0,0,0-1.325-.222,7.509,7.509,0,1,1-3.3-14.207,7.532,7.532,0,0,1,6,11.936C380.736,383.462,380.552,383.685,380.321,383.992Zm-5.537.521a5.707,5.707,0,1,0-5.675-5.72A5.675,5.675,0,0,0,374.784,384.513Z"
                        transform="translate(-367.297 -371.285)"
                        fill="currentColor"
                      ></path>
                    </svg>
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        {/* <button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          className="p-2 bg-gray-300 rounded-l-lg"
        >
          ←
        </button> */}
        {/* <div className="flex overflow-x-auto scrollbar-hide w-full mt-5">
          {serviceCards.map((card, index) => (
            <ServiceCard
              key={index}
              title={card.title}
              description={card.description}
              imageSrc={card.imageSrc}
              bgColor={card.bgColor}
            />
          ))}
        </div> */}
        {/* <CouponCardSlider /> */}
        {/* <button
          onClick={handleNext}
          disabled={currentIndex >= serviceCards.length - cardsToShow}
          className="p-2 bg-gray-300 rounded-r-lg"
        >
          →
        </button> */}
      </div>

      <div className="w-full flex flex-col md:flex-col xl:flex-row">
        <Sidebar selectedSubcategory={selectedSubcategory} setSelectedSubcategory={setSelectedSubcategory} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} categoryData={categoryData?.['14']} />

        <div className="w-full px-4 pt-3.5 pb-16 lg:p-6 xl:p-8 ">
          <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-3">
            {productData.map((product) => (
              <ProductCard
                key={product.id}
                id={product.id}
                title={product.name}
                price={product.price}
                image={productImg}
                del={null}
                storeId={product.storeId}
                percentage={"10%"}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
