import {
  FETCH_ADDRESS,
  FETCH_ORDERS,
  FETCH_ORDERSBYID,
} from "../contstants/apis";

export const fetchShippingAddress = async (makeRequest, id, cb) => {
  await makeRequest({
    url : `${FETCH_ADDRESS}/${id}`,
    onSuccessCallback : cb,
    isToastVisible : false
});
};

export const fetchBillingAddress = async (makeRequest, id, cb) => {
  await makeRequest({
   url :  `${FETCH_ADDRESS}/${id}`,
    onSuccessCallback : cb,
    isToastVisible : false
});
};

export const fetchOrderDetails = async (makeRequest, userId, cb, dispatch) => {
  await makeRequest({
   url :  `${FETCH_ORDERS}/${userId}`,
    onSuccessCallback : (res)=>dispatch(cb(res)),
    isToastVisible : false
});
};

export const fetchOrderDetailsById = async (
  makeRequest,
  orderId,
  cb,
  shippingCb,
  billingCb
) => {
  await makeRequest({
    url : `${FETCH_ORDERSBYID}/${orderId}`,
    onSuccessCallback : (res) => {
      if (res?.code === 200) {
        cb(res);
        billingCb(res?.data?.billingAddress);
        shippingCb(res?.data?.shippingAddress);
      }
    },
    isToastVisible : false
});
};
