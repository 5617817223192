import dummyImg from "../assets/images/dummy.jpg";
const ShopCard = ({ shopName, address, onClick, image, storeId }) => {

    return (
        <div
            onClick={() => onClick(storeId)}
            className="relative flex cursor-pointer items-center gap-4 rounded border border-gray-200 p-5"
        >
            <div className="relative flex h-16 w-16 shrink-0 items-center justify-center overflow-hidden rounded-full bg-gray-300">
                <img
                    alt="Logo"
                    className="object-cover w-full h-full"
                    src={image}
                    onError={(e) => {
                        e.currentTarget.onerror = null; // Prevent infinite loop if fallback image fails to load
                        e.currentTarget.src = dummyImg;
                    }}
                />
            </div>
            <div className="flex flex-col ml-4 rtl:mr-4">
                <span className="mb-2 text-lg font-semibold">{shopName}</span>
                <span className="flex text-xs">
                    <i className="ri-map-pin-2-line mr-1"></i>
                    {address}
                </span>
            </div>
        </div>
    );
};

export default ShopCard;
