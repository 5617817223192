import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: {},
    cartData: {},
    tempCartData: {},
    orderData: {},
    shopPanelData: {},
    searchParam: "",
    isSearch: false,
    quantity: 0,
    openCart: false
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
    setTempCartData: (state, action) => {
      state.tempCartData = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setShopPanelData: (state, action) => {
      state.shopPanelData = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
    setSearchParam: (state, action) => {
      state.searchParam = action.payload;
    },
    setOpenCart: (state, action) => {
      state.openCart = action.payload;
    }
  },
});

export const {
  setProducts,
  setCartData,
  setOrderData,
  setShopPanelData,
  setQuantity,
  setIsSearch,
  setSearchParam,
  setTempCartData,
  setOpenCart
} = productSlice.actions;

export default productSlice.reducer;
