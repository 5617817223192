import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import './components/shared/i18next/index.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#009F7F',
                colorPrimaryHover: '#039375'
              },
              Input: {
                activeBorderColor: '#039375',
                hoverBorderColor: '#039375',
              },
              Select: {
                colorPrimary: '#009F7F',
                colorPrimaryHover: '#039375'
              },
              Table: {
                tableRowHeight: '78px', // Set row height
                tableHeaderHeight: '44px', // Set header height
              },
            },
          }}
        >
    <App />
    </ConfigProvider>
    </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
