import { useState } from "react";
import CartConfirmModal from "../components/ConfirmModal";

const useConfirm = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null);

  const confirm = () => {
    setIsVisible(true);
    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  };

  const handleOk = () => {
    setIsVisible(false);
    if (resolvePromise) resolvePromise(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
    if (resolvePromise) resolvePromise(false);
  };

  const ConfirmModal = () =>
    isVisible && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <CartConfirmModal
          handleCurrent={handleCancel}
          handlePrevious={handleOk}
        />
      </div>
    );

  return { confirm, ConfirmModal };
};

export default useConfirm;
