import React from "react";

export const ProductNotFound = () => {
  return (
    <svg width="140" height="176" viewBox="0 0 231.91 292">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="1"
          y1="0.439"
          x2="0.369"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#029477"></stop>
          <stop offset="1" stopColor="#009e7f"></stop>
        </linearGradient>
      </defs>
      <g
        id="no_cart_in_bag_2"
        data-name="no cart in bag 2"
        transform="translate(-1388 -351)"
      >
        <ellipse
          id="Ellipse_2873"
          data-name="Ellipse 2873"
          cx="115.955"
          cy="27.366"
          rx="115.955"
          ry="27.366"
          transform="translate(1388 588.268)"
          fill="#ddd"
          opacity="0.25"
        ></ellipse>
        <path
          id="Path_18691"
          data-name="Path 18691"
          d="M29.632,0H170.368A29.828,29.828,0,0,1,200,30.021V209.979A29.828,29.828,0,0,1,170.368,240H29.632A29.828,29.828,0,0,1,0,209.979V30.021A29.828,29.828,0,0,1,29.632,0Z"
          transform="translate(1403 381)"
          fill="#009e7f"
        ></path>
        <path
          id="Rectangle_1852"
          data-name="Rectangle 1852"
          d="M30,0H170a30,30,0,0,1,30,30v0a30,30,0,0,1-30,30H12.857A12.857,12.857,0,0,1,0,47.143V30A30,30,0,0,1,30,0Z"
          transform="translate(1403 381)"
          fill="#006854"
        ></path>
        <path
          id="Rectangle_1853"
          data-name="Rectangle 1853"
          d="M42,0H158a42,42,0,0,1,42,42v0a18,18,0,0,1-18,18H18A18,18,0,0,1,0,42v0A42,42,0,0,1,42,0Z"
          transform="translate(1403 381)"
          fill="#006854"
        ></path>
        <path
          id="Path_18685"
          data-name="Path 18685"
          d="M446.31,246.056a30,30,0,1,1,30-30A30.034,30.034,0,0,1,446.31,246.056Zm0-53.294A23.3,23.3,0,1,0,469.9,216.056,23.471,23.471,0,0,0,446.31,192.762Z"
          transform="translate(1056.69 164.944)"
          fill="#006854"
        ></path>
        <path
          id="Path_18686"
          data-name="Path 18686"
          d="M446.31,375.181a30,30,0,1,1,30-30A30.034,30.034,0,0,1,446.31,375.181Zm0-53.294A23.3,23.3,0,1,0,469.9,345.181,23.471,23.471,0,0,0,446.31,321.887Z"
          transform="translate(1057.793 95.684)"
          fill="#009e7f"
        ></path>
        <circle
          id="Ellipse_2874"
          data-name="Ellipse 2874"
          cx="28.689"
          cy="28.689"
          r="28.689"
          transform="translate(1473.823 511.046)"
          fill="#006854"
        ></circle>
        <circle
          id="Ellipse_2875"
          data-name="Ellipse 2875"
          cx="15.046"
          cy="15.046"
          r="15.046"
          transform="translate(1481.401 547.854) rotate(-45)"
          fill="#009e7f"
        ></circle>
        <path
          id="Path_18687"
          data-name="Path 18687"
          d="M399.71,531.27a71.755,71.755,0,0,1,12.65-13.6c3.4-2.863-1.5-7.726-4.882-4.882a78.392,78.392,0,0,0-13.73,15c-2.56,3.644,3.424,7.1,5.962,3.485Z"
          transform="translate(1060.579 -35.703)"
          fill="#006854"
        ></path>
        <path
          id="Path_18688"
          data-name="Path 18688"
          d="M412.913,527.786a78.419,78.419,0,0,0-13.73-15c-3.38-2.843-8.289,2.017-4.882,4.882a71.785,71.785,0,0,1,12.65,13.6c2.535,3.609,8.525.162,5.962-3.485Z"
          transform="translate(1060.566 -35.704)"
          fill="#006854"
        ></path>
        <path
          id="Path_18689"
          data-name="Path 18689"
          d="M583.278,527.786a78.417,78.417,0,0,0-13.73-15c-3.38-2.843-8.289,2.017-4.882,4.882a71.768,71.768,0,0,1,12.65,13.6c2.535,3.609,8.525.162,5.962-3.485Z"
          transform="translate(970.304 -35.704)"
          fill="#006854"
        ></path>
        <path
          id="Path_18690"
          data-name="Path 18690"
          d="M570.075,531.27a71.77,71.77,0,0,1,12.65-13.6c3.4-2.863-1.5-7.726-4.882-4.882a78.407,78.407,0,0,0-13.73,15c-2.56,3.644,3.424,7.1,5.962,3.485Z"
          transform="translate(970.318 -35.703)"
          fill="#006854"
        ></path>
        <path
          id="Path_18692"
          data-name="Path 18692"
          d="M301.243,287.464a19.115,19.115,0,0,1,8.071,9.077,19.637,19.637,0,0,1,1.6,7.88v26.085a19.349,19.349,0,0,1-9.672,16.957c-10.048-6.858-16.544-17.742-16.544-30S291.2,294.322,301.243,287.464Z"
          transform="translate(1292.301 101.536)"
          fill="url(#linear-gradient)"
        ></path>
        <path
          id="Path_18693"
          data-name="Path 18693"
          d="M294.371,287.464a19.115,19.115,0,0,0-8.071,9.077,19.637,19.637,0,0,0-1.6,7.88v26.085a19.349,19.349,0,0,0,9.672,16.957c10.048-6.858,16.544-17.742,16.544-30S304.419,294.322,294.371,287.464Z"
          transform="translate(1118.301 101.536)"
          fill="url(#linear-gradient)"
        ></path>
      </g>
    </svg>
  );
};

export const CartBucket = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 12.686 16">
      <g transform="translate(-27.023 -2)">
        <g transform="translate(27.023 5.156)">
          <g>
            <path
              d="M65.7,111.043l-.714-9A1.125,1.125,0,0,0,63.871,101H62.459V103.1a.469.469,0,1,1-.937,0V101H57.211V103.1a.469.469,0,1,1-.937,0V101H54.862a1.125,1.125,0,0,0-1.117,1.033l-.715,9.006a2.605,2.605,0,0,0,2.6,2.8H63.1a2.605,2.605,0,0,0,2.6-2.806Zm-4.224-4.585-2.424,2.424a.468.468,0,0,1-.663,0l-1.136-1.136a.469.469,0,0,1,.663-.663l.8.8,2.092-2.092a.469.469,0,1,1,.663.663Z"
              transform="translate(-53.023 -101.005)"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      className="h-5 w-5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const HamburgerIcon = () => {
  return (
    <svg width="25.567" height="18" viewBox="0 0 25.567 18" className="false">
      <g transform="translate(-776 -462)">
        <rect
          width="12.749"
          height="2.499"
          rx="1.25"
          transform="translate(776 462)"
          fill="currentColor"
        ></rect>
        <rect
          width="25.567"
          height="2.499"
          rx="1.25"
          transform="translate(776 469.75)"
          fill="currentColor"
        ></rect>
        <rect
          width="17.972"
          height="2.499"
          rx="1.25"
          transform="translate(776 477.501)"
          fill="currentColor"
        ></rect>
      </g>
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg viewBox="0 0 17.048 18" width="17.05" height="18">
      <path
        d="M380.321,383.992l3.225,3.218c.167.167.341.329.5.506a.894.894,0,1,1-1.286,1.238c-1.087-1.067-2.179-2.131-3.227-3.236a.924.924,0,0,0-1.325-.222,7.509,7.509,0,1,1-3.3-14.207,7.532,7.532,0,0,1,6,11.936C380.736,383.462,380.552,383.685,380.321,383.992Zm-5.537.521a5.707,5.707,0,1,0-5.675-5.72A5.675,5.675,0,0,0,374.784,384.513Z"
        transform="translate(-367.297 -371.285)"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const HomeIcon = () => {
  return (
    <svg width="17.996" height="20.442" viewBox="0 0 17.996 20.442">
      <g transform="translate(-30.619 0.236)">
        <path
          d="M48.187,7.823,39.851.182A.7.7,0,0,0,38.9.2L31.03,7.841a.7.7,0,0,0-.211.5V19.311a.694.694,0,0,0,.694.694H37.3A.694.694,0,0,0,38,19.311V14.217h3.242v5.095a.694.694,0,0,0,.694.694h5.789a.694.694,0,0,0,.694-.694V8.335a.7.7,0,0,0-.228-.512ZM47.023,18.617h-4.4V13.522a.694.694,0,0,0-.694-.694H37.3a.694.694,0,0,0-.694.694v5.095H32.2V8.63l7.192-6.98L47.02,8.642v9.975Z"
          transform="translate(0 0)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.4"
        ></path>
      </g>
    </svg>
  );
};
export const UserIcon = () => {
  return (
    <svg width="16.577" height="18.6" viewBox="0 0 16.577 18.6">
      <g transform="translate(-95.7 -121.203)">
        <path
          d="M-7722.37,2933a.63.63,0,0,1-.63-.63c0-4.424,2.837-6.862,7.989-6.862s7.989,2.438,7.989,6.862a.629.629,0,0,1-.63.63Zm.647-1.251h13.428c-.246-3.31-2.5-4.986-6.713-4.986s-6.471,1.673-6.714,4.986Zm2.564-12.518a4.1,4.1,0,0,1,1.172-3,4.1,4.1,0,0,1,2.979-1.229,4.1,4.1,0,0,1,2.979,1.229,4.1,4.1,0,0,1,1.171,3,4.341,4.341,0,0,1-4.149,4.5,4.344,4.344,0,0,1-4.16-4.5Zm1.251,0a3.1,3.1,0,0,0,2.9,3.254,3.094,3.094,0,0,0,2.9-3.253,2.878,2.878,0,0,0-.813-2.109,2.88,2.88,0,0,0-2.085-.872,2.843,2.843,0,0,0-2.1.856,2.841,2.841,0,0,0-.806,2.122Z"
          transform="translate(7819 -2793.5)"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="0.6"
        ></path>
      </g>
    </svg>
  );
};

export const CartIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <g transform="translate(-127 -122)">
        <path
          d="M4.7,3.8H17.3a.9.9,0,0,1,.9.9V17.3a.9.9,0,0,1-.9.9H4.7a.9.9,0,0,1-.9-.9V4.7A.9.9,0,0,1,4.7,3.8ZM2,4.7A2.7,2.7,0,0,1,4.7,2H17.3A2.7,2.7,0,0,1,20,4.7V17.3A2.7,2.7,0,0,1,17.3,20H4.7A2.7,2.7,0,0,1,2,17.3ZM11,11C8.515,11,6.5,8.583,6.5,5.6H8.3c0,2.309,1.5,3.6,2.7,3.6s2.7-1.291,2.7-3.6h1.8C15.5,8.583,13.485,11,11,11Z"
          transform="translate(125 120)"
          fill="currentColor"
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export const Dropdown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 7.2"
      width="12"
      height="7.2"
      className="mt-1"
    >
      <path
        d="M6.002 5.03L10.539.265a.826.826 0 011.211 0 .94.94 0 010 1.275l-5.141 5.4a.827.827 0 01-1.183.026L.249 1.545a.937.937 0 010-1.275.826.826 0 011.211 0z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const ShoppingBag = () => {
  return (
    <svg width="14" height="16" className="shrink-0" viewBox="0 0 12.686 16">
      <g transform="translate(-27.023 -2)">
        <g transform="translate(27.023 5.156)">
          <g>
            <path
              d="M65.7,111.043l-.714-9A1.125,1.125,0,0,0,63.871,101H62.459V103.1a.469.469,0,1,1-.937,0V101H57.211V103.1a.469.469,0,1,1-.937,0V101H54.862a1.125,1.125,0,0,0-1.117,1.033l-.715,9.006a2.605,2.605,0,0,0,2.6,2.8H63.1a2.605,2.605,0,0,0,2.6-2.806Zm-4.224-4.585-2.424,2.424a.468.468,0,0,1-.663,0l-1.136-1.136a.469.469,0,0,1,.663-.663l.8.8,2.092-2.092a.469.469,0,1,1,.663.663Z"
              transform="translate(-53.023 -101.005)"
              fill="currentColor"
            ></path>
          </g>
        </g>
        <g transform="translate(30.274 2)">
          <g>
            <path
              d="M160.132,0a3.1,3.1,0,0,0-3.093,3.093v.063h.937V3.093a2.155,2.155,0,1,1,4.311,0v.063h.937V3.093A3.1,3.1,0,0,0,160.132,0Z"
              transform="translate(-157.039)"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const FilterIcon = () => {
  return (
    <svg width="18" height="14" className="mr-2 rtl:ml-2" viewBox="0 0 18 14">
      <path
        d="M942.581,1295.564H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1295.564,942.581,1295.564Z"
        transform="translate(-925 -1292.064)"
        fill="currentColor"
      ></path>
      <path
        d="M942.581,1951.5H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1951.5,942.581,1951.5Z"
        transform="translate(-925 -1939.001)"
        fill="currentColor"
      ></path>
      <path
        d="M1163.713,1122.489a2.5,2.5,0,1,0,1.768.732A2.483,2.483,0,0,0,1163.713,1122.489Z"
        transform="translate(-1158.213 -1122.489)"
        fill="currentColor"
      ></path>
      <path
        d="M2344.886,1779.157a2.5,2.5,0,1,0,.731,1.768A2.488,2.488,0,0,0,2344.886,1779.157Z"
        transform="translate(-2330.617 -1769.425)"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const CartAddIcon = () => {
  return (
    <svg className="h-4 w-4 ltr:mr-2.5 rtl:ml-2.5" viewBox="0 0 14.4 12">
      <g transform="translate(-288 -413.89)">
        <path
          fill="currentColor"
          d="M298.7,418.289l-2.906-4.148a.835.835,0,0,0-.528-.251.607.607,0,0,0-.529.251l-2.905,4.148h-3.17a.609.609,0,0,0-.661.625v.191l1.651,5.84a1.336,1.336,0,0,0,1.255.945h8.588a1.261,1.261,0,0,0,1.254-.945l1.651-5.84v-.191a.609.609,0,0,0-.661-.625Zm-5.419,0,1.984-2.767,1.98,2.767Zm1.984,5.024a1.258,1.258,0,1,1,1.319-1.258,1.3,1.3,0,0,1-1.319,1.258Zm0,0"
        ></path>
      </g>
    </svg>
  );
};
