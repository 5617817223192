

const LOGIN = `/auth/login`
const REGISTER = `/auth/user/register`
const DELETE_CART = `/cart`   // cart id will be added
const GET_STORES = `/public/stores`
const FETCH_CART_ITEMS = `/cart/user`
const CREATE_ORDER = `/order/create`
const FETCH_ORDERS = `/order/user`
const FETCH_ORDERSBYID = `/order`
const DELETE_CART_ITEMS = `/cart`
const ADD_CART = `/cart`
const FETCH_STORE_PRODUCTS = `/public/products/dashboard`
const FETCH_RELATED_PRODCUTS = `/public/products/dashboard`
const FETCH_PRODUCTBYID = `/public/products`
const FETCH_USER_ADDRESS = `/order/user/address`
const ADD_USER_ADDRESS = `/order/user/address`
const EDIT_USER_ADDRESS = `/order/user/address`
const DELETE_ADDRESS = `/order/user/address`
const FETCH_ADDRESS = `/order/address`
const ALL_PRODUCT_LIST = `/products/filter`
const TEMP_CART = `/tempCart`

export {
    LOGIN,
    REGISTER,
    DELETE_CART,
    GET_STORES,
    FETCH_CART_ITEMS,
    CREATE_ORDER,
    FETCH_ORDERS,
    FETCH_ORDERSBYID,
    DELETE_CART_ITEMS,
    ADD_CART,
    FETCH_STORE_PRODUCTS,
    FETCH_RELATED_PRODCUTS,
    FETCH_PRODUCTBYID,
    FETCH_USER_ADDRESS,
    ADD_USER_ADDRESS,
    DELETE_ADDRESS,
    EDIT_USER_ADDRESS,
    FETCH_ADDRESS,
    ALL_PRODUCT_LIST,
    TEMP_CART
}