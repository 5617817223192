import React from 'react'
import SectionHeader from '../../components/SectionHeader'

const sections = [
    {
        id: 'disclaimers',
        title: 'Disclaimers and Limitation of Liability',
        content: 'The Website is provided "as is" and "as available" without any warranties, either expressed or implied. Pickbazar shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use the Website.'
    },
    {
        id: 'intellectual-property',
        title: 'Intellectual Property',
        content: 'The Website and its original content, features, and functionality are owned by [Your Company] and are protected by international copyright, trademark, and other intellectual property laws.'
    },
    {
        id: 'privacy-policy',
        title: 'Privacy Policy',
        content: 'Your use of the Website is also governed by our Privacy Policy, which can be found [link to Privacy Policy]. By using the Website, you consent to the practices described in the Privacy Policy.'
    },
    {
        id: 'use-of-website',
        title: 'Use of the Website',
        content: 'You must be at least [Age] years old to use this Website. By using the Website, you represent and warrant that you are at least [Age] years old. You agree to use the Website for lawful purposes only and in a manner that does not infringe upon the rights of others.'
    },
    {
        id: 'acceptance',
        title: 'Acceptance of Terms',
        content: 'By using this Website, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please do not use the Website.'
    }
];

const TermCondition = () => {
    return (
        <section className="mx-auto w-full max-w-1920 bg-white pb-8 lg:pb-10 xl:pb-14">
            <SectionHeader title="Term & Condition" breadcrumbText="Term & Condition" breadcrumbLink="/" />
            <div className="mx-auto w-full max-w-screen-lg px-4 py-10">
                <div className="flex flex-col md:flex-row">
                    {/* Navigation Links */}
                    <nav className="mb-8 hidden md:mb-0 md:block md:w-60 lg:w-72 xl:w-80 mr-8">
                        <ol className="sticky z-10 md:top-28 lg:top-24">
                            {sections.map((section) => (
                                <li key={section.id} className='hover:text-primary'>
                                    <a href={`#${section.id}`} className="my-3 inline-flex cursor-pointer pl-4">{section.title}</a>
                                </li>
                            ))}
                        </ol>
                    </nav>

                    {/* Section Content */}
                    <div className="md:w-9/12 md:pb-10 md:pl-8 rtl:md:pr-8">
                        {sections.map((section) => (
                            <div key={section.id} id={section.id} className="mb-7 md:mb-10">
                                <h2 className="mb-4 text-lg font-bold md:text-xl lg:text-2xl">{section.title}</h2>
                                <div className="mt-15 leading-loose">{section.content}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermCondition;
