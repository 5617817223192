import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { setCartData } from "../../redux/reducers/products";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addAddress,
  createOrder,
  deleteAddress,
  fetchUserAddress,
  handleEditAddressFuc,
} from "../../services/checkout.service";
import { ProductNotFound } from "../../assets/cart/svg";
import { useTranslation } from "react-i18next";

const AddressModal = ({
  type,
  address,
  setAddress,
  toggleAddressModal,
  editAddress,
  AddUserAddress,
  error,
  loading,
  setAddressErrors
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div
        onClick={() => toggleAddressModal()}
        className="absolute inset-0 bg-black opacity-50"
      ></div>
      <div className="bg-white relative p-5 rounded max-w-xl w-full">
        <h1 className="text-lg font-semibold mb-4">
          {editAddress?.address
            ? type === "shipping"
              ? "Edit Shipping Address"
              : "Edit Billing Address"
            : type === "shipping"
            ? "Add Shipping Address"
            : "Add Billing Address"}
        </h1>

        <input
          name="address"
          type="text"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
            if (error?.address) {
              setAddressErrors((prev) => ({ ...prev, address: null }));
            }
          }}
          placeholder={
            editAddress?.address
              ? type === "shipping"
                ? "Edit Shipping Address"
                : "Edit Billing Address"
              : type === "shipping"
              ? "Add Shipping Address"
              : "Add Billing Address"
          }
          className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-0 focus:ring-0 border border-border-base rounded focus:border-primary h-12"
          // defaultValue={editAddress ? editAddress.address : address} // Pre-fill on edit
          required
        />
        {error?.address && (
          <p className="text-red-500 text-sm mt-1 pl-1">
            {error?.address}
          </p>
        )}

        <button
        disabled={loading}
          type="button"
          className="bg-primary disabled:cursor-not-allowed text-white py-2 mt-2 px-4 rounded"
          onClick={() => AddUserAddress(type)}
        >
          {editAddress?.address ? "Update Address" : "Add Address"}
        </button>
      </div>
    </div>
  );
};

const Checkout = () => {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const { loading } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShippingAddress, setModalShippingAddress] = useState(false);
  const [modalBillAddress, setModalBillAddress] = useState(false);
  const { user } = useSelector((state) => state.authSlice);
  const userName = user?.data?.data?.name;
  const [contactNumber, setContactNumber] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [orderNote, setOrderNote] = useState(null);
  const [editAddress, setEditAddress] = useState(null);
  const [userAddressList, setUserAddressList] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [errors, setErrors] = useState({});
  const [addressErrors, setAddressErrors] = useState({});
  const { cartData } = useSelector((state) => state.products);
  const { makeRequest } = useAxios();
  const { t } = useTranslation("Checkout");
  const [address, setAddress] = useState("");

  const handleUserAddress = () => {
    fetchUserAddress(
      makeRequest,
      user?.data?.data?.id,
      setUserAddressList,
      setAddress
    );
  };

  const handleSelectBiilingAddress = (id) => {
    setSelectedBillingAddress(id);
    setSelectedShippingAddress(id);
    // setChecked(false)
  };

  const handleSelectShippingAddress = (id) => {
    setSelectedShippingAddress(id);
    setErrors((prevErrors) => ({ ...prevErrors, selectedShippingAddress: "" }));
  };

  const handleDeleteAddress = (id) => {
    deleteAddress(makeRequest, id, handleUserAddress);
  };

  const handleKeyPress = (event) => {
    // Check if the key pressed is a letter using a regular expression
    if (/[a-zA-Z]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const handleEditAddress = (i, type) => {
    console.log('i',i);
    
    setEditAddress(i);
    setAddress(i?.address);
    setModalBillAddress(true);
  };

  const handleAddAddressSuccess = (type, res) => {
    handleUserAddress();
    setModalShippingAddress(false);
    setModalBillAddress(false);
    if (res?.data?.address === address && type === "billing") {
      handleSelectBiilingAddress(res?.data);
    }
    if (res?.data?.address === address && type === "shipping") {
      handleSelectShippingAddress(res?.data);
    }
    setAddress('')
  };

  const handleEditAddressSuccess = () => {
    handleUserAddress();
    setModalShippingAddress(false);
    setModalBillAddress(false);
    setAddress("");
    setEditAddress(null);
  };

  const AddUserAddress = (type) => {
    const newErrors = {};

    if (!address) {
      newErrors.address =
        "Address is required";
      setAddressErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    }
    const params = {
      userId: user?.data?.data?.id,
      address: address,
      type: "HOME",
    };
    !editAddress?.id
      ? addAddress(makeRequest, params, type, handleAddAddressSuccess)
      : handleEditAddressFuc(
          makeRequest,
          params,
          editAddress?.id,
          handleEditAddressSuccess
        );
  };

  const toggleAddressModal = () => {
    setModalShippingAddress(false);
    setModalBillAddress(false);
    setEditAddress(null);
    setAddress("");
  };

  const validateCheckout = () => {
    const newErrors = {};

    // Validate contact number
    if (!contactNumber) {
      newErrors.contactNumber = "Contact number is required.";
    } else if (!/^\d+$/.test(contactNumber)) {
      newErrors.contactNumber = "Please enter a valid contact number.";
    }

    // Validate shipping address
    if (!selectedShippingAddress) {
      newErrors.selectedShippingAddress =
        "Shipping address is required. Please add an address.";
    }

    // Validate order note (optional, but can add validation if needed)
    // if (!orderNote) {
    //   newErrors.orderNote = "Order note is required.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCheckout = async () => {
    if (!validateCheckout()) {
      // Validation failed, errors will be displayed
      return;
    }

    const formData = {
      currency: "USD",
      customerFullName: userName,
      customerContactNoPrimary: contactNumber,
      shippingAddress: selectedShippingAddress?.address,
      billingAddress: selectedShippingAddress?.address,
      deliverySchedule: "NA",
      orderNote: orderNote,
    };

    createOrder(makeRequest, formData, dispatch, navigate, setCartData);
  };

  useEffect(() => {
    localStorage.removeItem("isCheckoutRoute");
    fetchUserAddress(
      makeRequest,
      user?.data?.data?.id,
      setUserAddressList,
      setAddress
    );
  }, []);
  useEffect(() => {
    if (!isLoggedIn && location?.pathname === '/checkout') {
      navigate("/signin");
    }
  }, [isLoggedIn]);

  const filteredAddress = userAddressList?.data?.filter(
    (i) => i?.isDeleted === false
  );

  return (
    <>
      {isLoggedIn && <div className="bg-gray-100 px-4 py-8 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
        <div className="m-auto flex w-full max-w-5xl flex-col items-center rtl:space-x-reverse lg:flex-row lg:items-start lg:space-x-8">
          <div className="w-full space-y-6 lg:max-w-2xl">
            <div className="bg-white p-5 shadow-700 md:p-8">
              <div className="mb-5 flex items-center justify-between md:mb-8">
                <div className="flex items-center space-x-3 rtl:space-x-reverse md:space-x-4">
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-base text-white lg:text-xl">
                    1
                  </span>
                  <p className="text-lg capitalize lg:text-xl">
                    {t("contactNumber")}
                  </p>
                </div>
              </div>
              <div className="w-full">
                <input
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*$/.test(value)) {
                      setContactNumber(value);
                      if (errors.contactNumber) {
                        setErrors((prev) => ({ ...prev, contactNumber: null }));
                      }
                    }
                  }}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter Mobile Number"
                  className="flex w-full appearance-none items-center px-4 text-sm text-heading transition duration-300 ease-in-out focus:outline-0 focus:ring-0 border border-border-base rounded focus:border-primary h-12"
                />
                {errors.contactNumber && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.contactNumber}
                  </p>
                )}
              </div>
            </div>
            {/* <div className="bg-white p-5 shadow-700 md:p-8">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-lg capitalize lg:text-xl">
                  Billing Address
                </h2>
                <button
                  onClick={() => {
                    setModalBillAddress(true);
                  }}
                  className="text-sm font-semibold text-primary"
                >
                  <i className="ri-add-line mr-1"></i>
                  Add Billing Address
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {filteredAddress?.map((i, index) => (
                  <div
                    onClick={() => handleSelectBiilingAddress(i)}
                    key={index}
                    className={`relative text-sm font-semibold p-4 border ${
                      selectedBillingAddress?.id === i?.id
                        ? "border-primary"
                        : "border-gray-200"
                    } group`}
                  >
                    {i?.address ? i?.address : "No Billing Address Found"}

                    {i?.address && (
                      <>
                        <button
                          className="absolute top-2 right-8 w-[20px] h-[20px] bg-white rounded-full text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                          onClick={() => {
                            handleEditAddress(i);
                          }}
                        >
                          <i className="ri-pencil-line"></i>
                        </button>
                        <button
                          className="absolute top-2 right-2 w-[20px] h-[20px] bg-white rounded-full text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                          onClick={() => {
                            handleDeleteAddress(i?.id);
                          }}
                        >
                          <i className="ri-delete-bin-line"></i>
                        </button>
                      </>
                    )}
                  </div>
                ))}
                {errors.selectedBillingAddress && (
                  <p className="text-red-500 text-sm">
                    {errors.selectedBillingAddress}
                  </p>
                )}
              </div>
            </div> */}

            {/* Shipping Address Section */}
            <div className="bg-white p-5 shadow-700 md:p-8">
              <div className="flex justify-between items-center mb-5">
                <div className="flex items-center space-x-3 rtl:space-x-reverse md:space-x-4">
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-base text-white lg:text-xl">
                    2
                  </span>
                  <p className="text-lg capitalize lg:text-xl">
                    {t("shippingAddress")}
                  </p>
                </div>

                <button
                  onClick={() => {
                    setModalShippingAddress(true);
                  }}
                  className="text-sm font-semibold text-primary"
                >
                  <i className="ri-add-line mr-1"></i>{" "}
                  {t("Add Shipping Address")}
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {filteredAddress?.map((i, index) => (
                  <div
                    onClick={() => handleSelectShippingAddress(i)}
                    key={index}
                    className={`relative text-sm font-semibold p-4 border ${
                      selectedShippingAddress?.id === i?.id
                        ? "border-primary"
                        : "border-gray-200"
                    } group`}
                  >
                    {i?.address ? i?.address : "No Shipping Address Found"}

                    {i?.address && (
                      <>
                        <button
                        disabled={loading}
                          className="absolute top-2 right-8 w-[20px] h-[20px] bg-white rounded-full text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                          onClick={() => {
                            handleEditAddress(i);
                          }}
                        >
                          <i className="ri-pencil-line"></i>
                        </button>
                        <button
                          disabled={loading}
                          className="absolute top-2 right-2 w-[20px] h-[20px] bg-white rounded-full text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                          onClick={() => {
                            handleDeleteAddress(i?.id);
                          }}
                        >
                          <i className="ri-delete-bin-line"></i>
                        </button>
                      </>
                    )}
                  </div>
                ))}
                {errors.selectedShippingAddress && (
                  <p className="text-red-500 text-sm">
                    {errors.selectedShippingAddress}
                  </p>
                )}
              </div>
            </div>

            {/* <div className="bg-white p-5 shadow-700 md:p-8">
              <div className="mb-5 flex items-center justify-between md:mb-8">
                <div className="flex items-center space-x-3 rtl:space-x-reverse md:space-x-4">
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-base text-white lg:text-xl">
                    4
                  </span>
                  <p className="text-lg capitalize lg:text-xl">
                    Delivery Schedule
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                {[
                  "Express Delivery",
                  "Morning",
                  "Noon",
                  "Afternoon",
                  "Evening",
                ].map((schedule) => (
                  <div
                    key={schedule}
                    onClick={() => setSelectedSchedule(schedule)}
                    className={`relative p-4 rounded border cursor-pointer group hover:border-primary ${
                      selectedSchedule === schedule
                        ? "border-primary bg-gray-100"
                        : "border-gray-200"
                    }`}
                  >
                    <span className="text-sm font-semibold block mb-2">
                      {schedule}
                    </span>
                    <span className="text-sm block">
                      {schedule === "Express Delivery"
                        ? "90 min express delivery"
                        : schedule === "Morning"
                        ? "8.00 AM - 11.00 AM"
                        : schedule === "Noon"
                        ? "11.00 AM - 2.00 PM"
                        : schedule === "Afternoon"
                        ? "2.00 PM - 5.00 PM"
                        : "5.00 PM - 8.00 PM"}
                    </span>
                  </div>
                ))}
                {errors.selectedSchedule && (
                  <p className="text-red-500 text-sm">
                    {errors.selectedSchedule}
                  </p>
                )}
              </div>
            </div> */}

            <div className="bg-white p-5 shadow-700 md:p-8">
              <div className="mb-5 flex items-center justify-between md:mb-8">
                <div className="flex items-center space-x-3 rtl:space-x-reverse md:space-x-4">
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-base text-white lg:text-xl">
                    3
                  </span>
                  <p className="text-lg capitalize lg:text-xl">
                    {t("orderNote")}
                  </p>
                </div>
              </div>
              <div className="block">
                <div>
                  <textarea
                    id="orderNote"
                    name="orderNote"
                    className="flex w-full appearance-none items-center rounded px-4 py-3 text-sm transition duration-300 ease-in-out focus:outline-0 focus:ring-0 border border-border-base focus:border-primary"
                    autoComplete="off"
                    rows="4"
                    onChange={(e) => setOrderNote(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 mb-10 w-full sm:mb-12 lg:mb-0 lg:w-96">
            <div className="w-full">
              <div className="mb-4 flex flex-col items-center space-x-4 rtl:space-x-reverse">
                <span className="text-base font-bold">{t("yourOrder")}</span>
              </div>
              {cartData?.data?.items?.length > 0 ? (
                cartData.data.items.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col border-b border-gray-200 py-3"
                  >
                    <div className="flex justify-between py-2">
                      <div className="flex items-center justify-between text-base">
                        <span className="text-sm">
                          <span className="text-sm font-bold">
                            {item.quantity}
                          </span>
                          <span className="mx-2">x</span>
                          <span>{item.productName}</span> |<span>1 pcs</span>
                        </span>
                      </div>
                      <span className="text-sm ">${item.totalPrice}</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center justify-center gap-2">
                  <ProductNotFound />
                  <p className="text-center text-sm">{t("noProductFound")}</p>
                </div>
              )}

              <div className="mt-4 space-y-2">
                <div className="flex justify-between">
                  <p className="text-sm">{t("subTotal")}</p>
                  <span className="text-sm">
                    ${cartData?.data?.totalCartValue || 0}
                  </span>
                </div>
              </div>

              <button
              disabled={cartData?.data?.items?.length ? false : true}
                onClick={handleCheckout}
                className="inline-flex disabled:cursor-not-allowed items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition disabled:bg-primary-hover disabled:cursor-not-allowed duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-primary-700 bg-primary text-white border border-transparent hover:bg-primary-hover px-5 py-0 h-12 mt-5 w-full"
              >
                {t("placeOrder")}
              </button>
            </div>
          </div>
        </div>
      </div>}
      {/* modal for billing address */}
      {modalBillAddress && (
        <AddressModal
          address={address}
          setAddress={setAddress}
          toggleAddressModal={toggleAddressModal}
          editAddress={editAddress}
          type={"billing"}
          AddUserAddress={AddUserAddress}
          setAddressErrors={setAddressErrors}
          loading={loading}
        />
      )}
      {/* Modal for Shipping Address */}
      {modalShippingAddress && (
        <AddressModal
          address={address}
          setAddress={setAddress}
          toggleAddressModal={toggleAddressModal}
          editAddress={editAddress}
          type={"shipping"}
          AddUserAddress={AddUserAddress}
          error={addressErrors}
          setAddressErrors={setAddressErrors}
          loading={loading}
        />
      )}
    </>
  );
};

export default Checkout;
