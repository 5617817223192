import {
  ADD_CART,
  ALL_PRODUCT_LIST,
  DELETE_CART_ITEMS,
  FETCH_CART_ITEMS,
  FETCH_PRODUCTBYID,
  TEMP_CART,
} from "../contstants/apis";
import { setCartData, setShopPanelData, setTempCartData } from "../redux/reducers/products";

export const fetchTempCartItems = async (makeRequest, dispatch, uuid) => {
  await makeRequest({
   url :  `${TEMP_CART}/${uuid}`,
    reduxAction : setTempCartData,
    onSuccessCallback : (data) => {
      if (data?.code === 400) {
        dispatch(setTempCartData(null));
      }
    },
    isToastVisible : false
});
};

export const fetchCartItems = async (makeRequest, dispatch) => {
  await makeRequest({
    url : FETCH_CART_ITEMS,
    reduxAction : setCartData,
    onSuccessCallback : (data) => {
      if (data?.code === 400) {
        dispatch(setCartData(null));
      }
    },
    isToastVisible : false
});
};

export const handleDeleteCartItems = async (
  makeRequest,
  dispatch,
  isLoggedIn,
  userCartId,
  tempCartId,
  itemId,
  uuid, 
  bool = true
) => {
  await makeRequest({
    url : isLoggedIn
      ? `${DELETE_CART_ITEMS}/${userCartId}/items/${itemId}`
      : `${TEMP_CART}/${tempCartId}/items/${itemId}`,
    method : "DELETE",
   onSuccessCallback : () => {
      isLoggedIn
        ? fetchCartItems(makeRequest, dispatch)
        : fetchTempCartItems(makeRequest, dispatch, uuid);
    },
   isToastVisible : false,
   toggleLoader: bool
});
};

export const editCartQuantity = async (
  makeRequest,
  dispatch,
  isLoggedIn,
  item,
  action,
  cartStoreId,
  tempCartStoreId,
  userCartId,
  tempCartId,
  uuid
) => {
  const newQuantity =
    action === "increase" ? item.quantity + 1 : item.quantity - 1;
  if (newQuantity >= 1) {
    const formData = [
      {
        productId: item.productId,
        quantity: newQuantity,
      },
    ];
    await makeRequest({
      url : isLoggedIn
        ? `${ADD_CART}/${cartStoreId}`
        : `${TEMP_CART}/${uuid}/${tempCartStoreId}`,
      method : "POST",
      data : formData,
      onSuccessCallback : () => {
        isLoggedIn
          ? fetchCartItems(makeRequest, dispatch)
          : fetchTempCartItems(makeRequest, dispatch, uuid);
      },
      isToastVisible : false
  });
  } else {
    await makeRequest({
     url :  isLoggedIn
        ? `${DELETE_CART_ITEMS}/${userCartId}/items/${item.productId}`
        : `${TEMP_CART}/${tempCartId}/items/${item.productId}`,
      method : "DELETE",
      onSuccessCallback : () => {
        isLoggedIn
          ? fetchCartItems(makeRequest, dispatch)
          : fetchTempCartItems(makeRequest, dispatch, uuid);
      },
     isToastVisible : false
  });
  }
};

export const fetchShopData = async (
  makeRequest,
  id,
  debouncedSearchQuery,
  selectedCategory,
  selectedSubcategory,
  page,
  pageSize,
  setIsShopFetching
) => {
  setIsShopFetching(true)
  const requestData = {
    storeId: [id],
    b: debouncedSearchQuery,
    category: selectedCategory?.categoryName ?? "",
    subCategory: selectedSubcategory?.subCategoryName ?? "",
    e:1
  };
  await makeRequest({
     url : `${ALL_PRODUCT_LIST}?page=${page - 1}&size=${pageSize}`,
    method : "POST",
    data : requestData,
    reduxAction : setShopPanelData,
    onSuccessCallback : (response) => {
      if (response?.code === 200) {
        setIsShopFetching(false);
      }
    },
    isToastVisible : false
});
};

export const fetchCategories = async (makeRequest, id, cb) => {
  const url = `categories/search/category?storeIds=${id}&categoryName=`;
  await makeRequest({
    url,
    onSuccessCallback : (response) => {
      if (response?.code === 200) {
        cb(response?.data?.data);
      }
    },
    isToastVisible : false
});
};

export const updateCart = async (
  makeRequest,
  isLoggedIn,
  newQuantity,
  productId,
  storeId,
  cb,
  uuid
) => {
  const formData = [{ productId: productId, quantity: newQuantity }];
  await makeRequest({
    url : isLoggedIn ? `${ADD_CART}/${storeId}` : `${TEMP_CART}/${uuid}/${storeId}`,
    method : "POST",
    data : formData,
    onSuccessCallback : () => {
      if (cb) {
        cb();
      }
    },
    isToastVisible : false,
    toggleLoader : false
});
};

export const fetchProducts = async(makeRequest, productId, storeId, cb) => {
 await makeRequest({
   url :  `${FETCH_PRODUCTBYID}/${productId}/store/${storeId}`,
    onSuccessCallback : (res) => {
      cb(res.data);
    },
    isToastVisible : false
});
};
