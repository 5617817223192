import { Button } from 'antd'
import React from 'react'

const CustomButton = ({ title, className, size, onClick, htmlType = 'button' }) => {
  return (
    <Button onClick={onClick} size={size} className={`bg-primary text-white rounded px-3 py-2 ${className}`} htmlType={htmlType}>
      {title}
    </Button>
  );
};


export default CustomButton
