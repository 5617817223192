import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCartData,
  setIsSearch,
  setOpenCart,
  setSearchParam,
  setTempCartData,
} from "../../redux/reducers/products";
import Logo from "../Logo";
import {
  CartIcon,
  CloseIcon,
  HamburgerIcon,
  HomeIcon,
  SearchIcon,
  UserIcon,
} from "../../assets/cart/svg";
import { logout } from "../../redux/reducers/authSlice";

const mobileNavLinks = [
  { title: "Shops", link: "/" },
  { title: "Contact", link: "/contact" },
  { title: "FAQ", link: "/faqs" },
  { title: "Terms & Conditions", link: "/terms-and-conditions" },
  { title: "Customer Refund Policy", link: "/customer-refund-policy" },
  { title: "Vendor Refund Policy", link: "/vendor-policy" },
];

const Nav = () => {
  const location = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSearch, searchParam } = useSelector((state) => state.products);
  const profileMenuRef = useRef(null);

  const toggleSearch = (bool) => {
    dispatch(setIsSearch(bool));
    if (searchParam?.length) {
      dispatch(setSearchParam(""));
    }
  };

  const handleLogout = () => {
    dispatch(logout({}));
    dispatch(setTempCartData(null));
    dispatch(setCartData(null));
    navigate("/signin");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setOpenProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <nav className="visible fixed bottom-0 z-10 flex h-12 w-full justify-between bg-white py-1.5 px-2 shadow-400 left-0 rtl:right-0 md:h-14 lg:hidden">
        <button
          onClick={() => setMobileMenu(!mobileMenu)}
          className="flex h-full items-center justify-center p-2 focus:text-primary focus:outline-0"
        >
          <span className="sr-only">Burger Menu</span>
          <HamburgerIcon />
        </button>
        {location?.pathname.includes("shop-panel") && (
          <button
            onClick={() => toggleSearch(!isSearch)}
            className="flex h-full items-center justify-center p-2 focus:text-primary focus:outline-0"
            tabIndex="0"
          >
            <span className="sr-only">Search</span>
            <SearchIcon />
          </button>
        )}
        <Link
          to="/"
          className="flex h-full items-center justify-center p-2 focus:text-primary focus:outline-0"
        >
          <span className="sr-only">Home</span>
          <HomeIcon />
        </Link>

        <button
          onClick={() => {
            dispatch(setOpenCart(true));
          }}
          className="product-cart relative flex h-full items-center justify-center p-2 focus:text-primary focus:outline-0"
        >
          <span className="sr-only">Cart</span>
          <CartIcon />
        </button>
        <div
          ref={profileMenuRef}
          className="relative inline-block ltr:text-left rtl:text-right"
        >
          <button
            onClick={() => {
              setOpenProfileMenu(!openProfileMenu);
            }}
            className="product-cart relative flex h-full items-center justify-center p-2 focus:text-primary focus:outline-0"
          >
            <span className="sr-only">Profile</span>
            <UserIcon />
          </button>
          {openProfileMenu && (
            <ul className="absolute mt-2 w-36 rounded bg-white pb-2 shadow-lg focus:outline-none right-0 top-[-100px] ltr:right-0 ltr:origin-top-right rtl:left-0 rtl:origin-top-left transform opacity-100 scale-100">
              <li>
                <button className="block w-full py-2.5 px-6 text-sm font-semibold capitalize transition duration-200 hover:text-primary focus:outline-0 ltr:text-left rtl:text-right">
                  <Link
                    to="/order-status"
                    onClick={() => setOpenProfileMenu(false)}
                  >
                    My Orders
                  </Link>
                </button>
              </li>

              <li>
                <button
                  onClick={handleLogout}
                  className="block w-full py-2.5 px-6 text-sm font-semibold capitalize transition duration-200 hover:text-primary focus:outline-0 ltr:text-left rtl:text-right"
                >
                  Logout
                </button>
              </li>
            </ul>
          )}
        </div>
      </nav>
      {/* Mobile Menu */}
      {mobileMenu && (
        <div
          className={`lg:hidden fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-0 transition-opacity duration-300 ${
            mobileMenu ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <section
            className={`fixed top-0 left-0 z-50 h-full w-full max-w-md bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
              mobileMenu ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <div className="flex h-full flex-col">
              <div className="fixed top-0 z-20 flex w-full max-w-md items-center justify-between border-b border-gray-200 border-opacity-75 bg-white p-5 px-5 md:px-6">
                <Link
                  onClick={() => setMobileMenu(false)}
                  className="inline-flex w-24 md:w-auto"
                  to="/"
                >
                  <span className="relative h-[2.125rem] w-32 overflow-hidden md:w-[8.625rem]">
                    <Logo />
                  </span>
                </Link>
                <button
                  onClick={() => setMobileMenu(false)}
                  className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 transition-all duration-200 hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-0"
                >
                  <span className="sr-only">close</span>
                  <CloseIcon />
                </button>
              </div>
              <div className="pt-20">
                <ul className="grow">
                  {mobileNavLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        onClick={() => setMobileMenu(false)}
                        className="flex items-center px-5 py-3 text-sm font-semibold capitalize transition duration-200 cursor-pointer text-heading hover:text-primary md:px-6"
                        title={link.title}
                        to={link.link}
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Nav;
