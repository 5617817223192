// import { Spin } from 'antd';

// const GlobalLoader = () => {
//     return (
//         <div style={{
//             height: '100%',
//             position: 'fixed',
//             display: 'flex',
//             justifyContent: 'center',
//             top: '50%',
//             opacity: .25,
//             alignItems: 'center',
//             left: '50%',
//             width: '100%',
//             background: '#000',
//             zIndex: 40,
//             transform: 'translate(-50%, -50%)',
//             textAlign: 'center'
//         }} className=''>
//             <Spin size="large" />
//         </div>
//     );
// };

// export default GlobalLoader;

import React from "react";

const GlobalLoader = ({loading}) => {
  return (
    // <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
    //   <div className="relative">
    //     {/* Outer circle */}
    //     <div className="w-24 h-24 rounded-full border-8 border-gray-200"></div>

    //     {/* Spinning segment */}
    //     <div className="absolute top-0 left-0 w-24 h-24">
    //       <div className="w-24 h-24 rounded-full border-8 border-transparent border-t-primary animate-spin"></div>
    //     </div>

    //     {/* Loading text */}
    //     <div className="absolute inset-0 flex items-center justify-center p-2">
    //       <span className="text-white font-semibold text-sm">Loading...</span>
    //     </div>
    //   </div>
    // </div>
    <>
      <div style={{ height: "4px", overflow: "hidden", zIndex: 99999}} className="sticky">
      {loading && <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(90deg, #ff4d4f, #40a9ff, #ffc53d, #73d13d)",
            backgroundSize: "200% 100%",
            animation: "loadingAnimation 2s infinite",
          }}
        ></div>}
      </div>

      <style>
        {`
          @keyframes loadingAnimation {
            0% {
              background-position: 200% 0;
            }
            100% {
              background-position: -200% 0;
            }
          }
        `}
      </style>
    </>
  );
};

export default GlobalLoader;
