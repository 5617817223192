import { Input } from 'antd'
import React from 'react'

const CustomInput = (props) => {
      const { type } = props;

      switch (type) {
            case "password": return <Input.Password
                  className='h-12 
                  rounded
                  w-full 
                  px-3 
                  py-2 
                  bg-white 
                  border
                  border-slate-300 
                  text-sm 
                  shadow-sm 
                  placeholder-slate-400
                  focus:outline-none 
                  enabled:focus:border-[#009F7F] 
                  focus:ring-1 
                  focus:ring--[#009F7F]
                  enabled:hover:border-[#009F7F]
                  disabled:bg-slate-50 
                  disabled:text-slate-500 
                  disabled:border-slate-200 
                  disabled:shadow-none
                  invalid:border-pink-500 
                  invalid:text-pink-600
                  focus:invalid:border-pink-500 
                  focus:invalid:ring-pink-500
                  box-shadow-none'
                  {...props}
            />;
            default: return <Input
                  className='h-12 
                  rounded
                  block 
                  w-full 
                  px-3 
                  py-2 
                  bg-white 
                  border
                  border-slate-300 
                  text-sm 
                  shadow-sm 
                  placeholder-slate-400
                  focus:outline-none 
                  enabled:focus:border-[#009F7F] 
                  focus:ring-1 
                  focus:ring--[#009F7F]
                  enabled:hover:border-[#009F7F]
                  disabled:bg-slate-50 
                  disabled:text-slate-500 
                  disabled:border-slate-200 
                  disabled:shadow-none
                  invalid:border-pink-500 
                  invalid:text-pink-600
                  focus:invalid:border-pink-500 
                  focus:invalid:ring-pink-500
                  box-shadow-none'
                  style={{ boxShadow: 'none' }}
                  {...props}
            />
      }
}

export default CustomInput