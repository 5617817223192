
import facebook from '../../assets/svg/facebook.svg'
import twitter from '../../assets/svg/twitter.svg'
import insta from '../../assets/svg/insta.svg'
import { Link } from 'react-router-dom';
import Logo from '../Logo';
const Footer = () => {
  return (
    <footer className="bg-white rounded-md bottom-0 left-0 right-0">
      <div className="flex w-full flex-col border-t border-t-border-100 bg-white px-5 md:px-10  lg:px-[50px] xl:px-16">
        <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 pt-16 md:grid-cols-3 lg:pt-24 lg:pb-16 xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] xl:gap-8 2xl:grid-cols-5">
          <div className="flex flex-col">
            <div className="mb-[2px] flex h-16 items-start">
            <Link className="inline-flex py-3 lg:mx-0" to="/">
              <span className="relative h-[2.125rem] w-32 flex items-center overflow-hidden md:w-[8.625rem]">
                {/* <img alt="Pickbazar" className="object-contain" src={logo} /> */}
                <Logo/>
              </span>
            </Link>
            </div>
            <a
              className="text-sm not-italic mb-7 text-heading"
              href="https://www.google.com/maps/place/NY State Thruway, New York, USA"
            >
              NY State Thruway, New York, USA
            </a>
            <a className="mb-1 text-sm text-heading" href="mailto:demo@demo.com">
              demo@demo.com
            </a>
            <a className="text-sm text-heading" href="tel:+129290122122">
              +129290122122
            </a>
            <div>
              <div className="flex items-center gap-4 mt-4">
                <a
                  className="text-primary hover:text-primary-hover"
                  target="_blank"
                  href="https://www.facebook.com/redqinc"
                >
                  <img className='w-6 h-6' src={facebook} />
                </a>
                <a
                  className="text-primary hover:text-primary-hover"
                  target="_blank"
                  href="https://twitter.com/RedqTeam"
                >
                  <img className='w-6 h-6' src={twitter} />
                </a>
                <a
                  className="text-primary hover:text-primary-hover"
                  target="_blank"
                  href="https://www.instagram.com/redqteam"
                >
                  <img className='w-6 h-6' src={insta} />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mt-3 mb-4 font-semibold text-heading lg:mb-7">
              Explore
            </h3>
            <ul className="space-y-3">
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/"
                >
                  Shops
                </Link>
              </li>
              {/* <li>
                <a
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  href="/authors"
                >
                  Authors
                </a>
              </li>
              <li>
                <a
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  href="/flash-sales"
                >
                  Flash Deals
                </a>
              </li>
              <li>
                <a
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  href="/offers"
                >
                  Coupon
                </a>
              </li> */}
            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="mt-3 mb-4 font-semibold text-heading lg:mb-7">
              Customer Service
            </h3>
            <ul className="space-y-3">
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/faqs"
                >
                  FAQ &amp; Helps
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/vendor-policy"
                >
                  Vendor Refund Policies
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/customer-refund-policy"
                >
                  Customer Refund Policies
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="mt-3 mb-4 font-semibold text-heading lg:mb-7">
              Our information
            </h3>
            <ul className="space-y-3">
              {/* <li>
                <a
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  href="/manufacturers"
                >
                  Manufacturers
                </a>
              </li> */}
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/privacy-policy"
                >
                  Privacy policies
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/terms-and-conditions"
                >
                  Terms &amp; conditions
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm transition-colors text-heading hover:text-orange-500"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-full md:col-span-2 lg:col-auto">
            <div className="flex flex-col">
              <h3 className="mt-3 mb-7 text-xl font-semibold text-heading">
                Subscribe Now
              </h3>
              <p className="mb-7 text-sm text-heading">
                Subscribe your email for newsletter and featured news based on
                your interest
              </p>
              <div className="flex flex-col">
                <form noValidate="">
                  <div className="relative flex  w-full rounded border border-gray-200 bg-gray-50 pr-11 rtl:pl-11">
                    <input
                      type="email"
                      id="email_subscribe"
                      name="email"
                      placeholder="Write your email here"
                      style={{ outline: 'none' }}
                      className="h-14 w-full border-0 bg-transparent text-sm text-body outline-none focus:outline-0 pl-5 rtl:pr-5"
                    />
                    <button
                      //   className="absolute top-1/2 -mt-2 right-2 rtl:left-2"
                      className="pr-3"
                    >
                      <i className='ri-send-plane-2-fill text-slate-400 text-xl'></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-full gap-2 pt-8 pb-20 mt-8 border-t border-gray-200 lg:mt-0 lg:flex-row lg:justify-between lg:border-t-0 lg:pb-12">
          <span className="order-2 text-sm shrink-0 text-heading lg:order-1">
            ©2024{" "}
            <a
              className="font-medium text-heading"
              href="https://pickbazar.redq.io"
            >
              Efactura
            </a>
            . Copyright . All rights reserved worldwide.{" "}
            <a className="font-medium text-heading" href="https://redq.io">
              Efactura
            </a>
          </span>
          <div className="flex flex-wrap items-center justify-center order-1 gap-4 mb-5 lg:order-2 lg:mb-0 lg:justify-end lg:gap-x-5 lg:gap-y-3"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
