import React, { useState, useEffect, useRef } from "react";
import userIcon from "../../assets/userIcon.webp";
import { logout } from "../../redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  setCartData,
  setIsSearch,
  setOpenCart,
  setSearchParam,
  setTempCartData,
} from "../../redux/reducers/products";
import Nav from "./Nav";
import { Dropdown, SearchIcon } from "../../assets/cart/svg";
import CustomButton from "../CustomButton";
import GlobalLoader from "./GlobalLoader";

const Navbar = ({loading}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const { isSearch, searchParam, cartData } = useSelector(
    (state) => state.products
  );
  
  const profileMenuRef = useRef(null);
  const { isLoggedIn } = useSelector((state) => state.authSlice);

  const handleLogout = () => {
    dispatch(logout({}));
    dispatch(setTempCartData(null));
    dispatch(setCartData(null));
    navigate("/signin");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setOpenProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleSearch = (bool) => {
    dispatch(setIsSearch(bool));
    if (searchParam?.length) {
      dispatch(setSearchParam(""));
    }
  };

  const handleSearch = (value) => {
    dispatch(setSearchParam(value));
  };

  const navlinks = [
    { title: "Shop", link: "/" },
    { title: "Contact", link: "/contact" },
    {
      title: "Pages",
      isDropdown: true,
      dropdownLinks: [
        { title: "FAQ", link: "/faqs" },
        { title: "Terms & Conditions", link: "/terms-and-conditions" },
        { title: "Customer Refund Policy", link: "/customer-refund-policy" },
        { title: "Vendor Refund Policy", link: "/vendor-policy" },
      ],
    },
  ];

  return (
    <>
      <header className="top-0 z-50 w-full transition-all sticky border-b shadow-sm lg:h-22 pt-1 lg:block ">
        <div className="fixed inset-0 -z-10 h-[100vh] w-full bg-black/50 hidden"></div>
        <div className="">
          <div className="flex w-full transform-gpu items-center justify-between bg-white px-5 transition-transform duration-300 lg:h-22 lg:px-6 2xl:px-8">
            <div className="flex shrink-0 grow-0 basis-auto flex-wrap items-center ltr:mr-auto rtl:ml-auto lg:w-auto lg:flex-nowrap">
              <Link className="inline-flex py-3 mx-auto lg:mx-0" to={ location?.pathname === '/checkout' ? `/shop-panel/${cartData?.data?.storeId}` : "/"}>
                <span className="relative h-[2.125rem] w-32 flex items-center justify-center overflow-hidden md:w-[8.625rem]">
                  <h1 className="text-2xl text-primary font-semibold text-center">
                    <span className="text-red-700 text-4xl">E</span>factura
                  </h1>
                </span>
              </Link>
            </div>
            {isSearch && (
              <div className="absolute top-0 left-0 lg:left-[15%] z-20 flex h-full lg:w-1/2 w-full items-center justify-center space-x-4 bg-light px-5 py-1.5 ">
                <div className="w-full sm:w-3/4 lg:w-full  lg:max-w-3xl">
                  <div className="relative flex rounded md:rounded-lg h-11 md:h-12">
                    <input
                      id="grocery search at header"
                      type="text"
                      autocomplete="off"
                      onChange={(e) => handleSearch(e.target.value)}
                      className="search item-center flex h-full w-full appearance-none overflow-hidden truncate rounded-lg text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:outline-0 focus:ring-0 lg:border-primary-400 search-minimal bg-gray-100 pl-10 pr-4 md:pl-14 border border-transparent focus:border-primary focus:bg-light"
                      name="search"
                      placeholder="Search your products from here"
                      value={searchParam}
                    />
                    <button className="absolute flex h-full w-10 items-center justify-center text-body transition-colors duration-200 hover:text-primary-hover focus:text-primary-hover focus:outline-0 ltr:left-0 rtl:right-0 md:w-14">
                      <span className="sr-only">Search</span>
                      <SearchIcon />
                    </button>
                  </div>
                </div>
                <button
                  data-variant="custom"
                  onClick={() => toggleSearch(false)}
                  className="hidden lg:inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-primary-700 px-5 py-0 h-[40px] md:h-12 border border-primary-400 bg-gray-100 !px-4 text-primary lg:inline-flex"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
            <div className="flex shrink-0 items-center space-x-7 rtl:space-x-reverse 2xl:space-x-10">
              <ul className="hidden lg:flex shrink-0 items-center md:space-x-7 rtl:space-x-reverse xl:flex 2xl:space-x-10">
                {navlinks.map((link, index) =>
                  link.isDropdown ? (
                    <li
                      key={index}
                      className="menuItem group relative mx-3 cursor-pointer py-3 xl:mx-4"
                    >
                      <div className="flex items-center gap-2 group-hover:text-primary">
                        <span className="text-brand-dark group-hover:text-brand relative inline-flex items-center py-2 font-normal rtl:left-0">
                          {link.title}
                        </span>
                        <Dropdown />
                      </div>
                      <ul className="shadow-dropDown invisible absolute top-full right-0 z-30 w-[240px] rounded-md bg-white py-4 opacity-0 shadow transition-all duration-300 group-hover:visible group-hover:opacity-100 ltr:left-0 rtl:right-0 xl:w-[240px]">
                        {link.dropdownLinks.map(
                          (dropdownLink, dropdownIndex) => (
                            <li
                              key={dropdownIndex}
                              className="hover:filter-green group py-[10px] px-[22px] transition-all duration-200 hover:pl-[25px] hover:opacity-100"
                            >
                              <Link
                                className="flex items-center font-normal no-underline transition duration-200 hover:text-primary focus:text-primary"
                                to={dropdownLink.link}
                              >
                                {dropdownLink.title}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </li>
                  ) : (
                    <li key={index}>
                      <Link
                        className="flex items-center font-normal no-underline transition duration-200 hover:text-primary focus:text-primary"
                        to={link.link}
                      >
                        {link.title}
                      </Link>
                    </li>
                  )
                )}
              </ul>
              {location?.pathname.includes("shop-panel") && (
                <button
                  data-variant="custom"
                  onClick={() => toggleSearch(true)}
                  className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-primary-700 px-5 py-0 h-12 hidden h-[38px] w-[38px] items-center  gap-2 rounded-full border border-border-200 bg-light !p-1 text-sm !font-normal focus:!shadow-none focus:!ring-0 md:text-base lg:!flex"
                >
                  <SearchIcon />
                </button>
              )}
              {isLoggedIn ? (
                <div className="hidden lg:flex items-center m-0">
                  <div className="inline-flex">
                    <div
                      ref={profileMenuRef}
                      className="relative inline-block ltr:text-left rtl:text-right"
                    >
                      <button
                        onClick={() => setOpenProfileMenu(!openProfileMenu)}
                        className="flex items-center focus:outline-0"
                        type="button"
                      >
                        <div className="relative cursor-pointer overflow-hidden rounded-full h-[38px] w-[38px] border-2">
                          <img alt="user name" src={userIcon} />
                        </div>
                      </button>
                      {openProfileMenu && (
                        <ul className="absolute mt-2 w-48 rounded bg-white pb-2 shadow-lg focus:outline-none right-0 ltr:right-0 ltr:origin-top-right rtl:left-0 rtl:origin-top-left transform opacity-100 scale-100">
                          <li>
                            <button className="block w-full py-2.5 px-6 text-sm font-semibold capitalize transition duration-200 hover:text-primary focus:outline-0 ltr:text-left rtl:text-right">
                              <Link
                                to="/order-status"
                                onClick={() => setOpenProfileMenu(false)}
                              >
                                My Orders
                              </Link>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                dispatch(setOpenCart(true));
                                setOpenProfileMenu(false);
                              }}
                              className="block w-full py-2.5 px-6 text-sm font-semibold capitalize transition duration-200 hover:text-primary focus:outline-0 ltr:text-left rtl:text-right"
                            >
                              My Cart
                            </button>
                          </li>
                          {cartData?.data?.items?.length && <li>
                            <button className="block w-full py-2.5 px-6 text-sm font-semibold capitalize transition duration-200 hover:text-primary focus:outline-0 ltr:text-left rtl:text-right">
                               <Link
                                to="/checkout"
                                onClick={() => setOpenProfileMenu(false)}
                              >
                                Checkout
                              </Link>
                            </button>
                          </li>}
                          <li>
                            <button
                              onClick={handleLogout}
                              className="block w-full py-2.5 px-6 text-sm font-semibold capitalize transition duration-200 hover:text-primary focus:outline-0 ltr:text-left rtl:text-right"
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // <button
                //   onClick={() => navigate("/signin")}
                //   className="px-4 pt-[5px] pb-[8px] font-semibold text-white bg-primary rounded"
                // >
                //   Login
                // </button>
                <CustomButton
                  onClick={() => navigate("/signin")}
                  title={"Login"}
                  size={"large"}
                />
              )}
            </div>
          </div>
        </div>
        <GlobalLoader loading={loading}/>
      </header>
      
      
      <Nav />
    </>
  );
};

export default Navbar;
