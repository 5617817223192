import React from "react";
import { Link } from "react-router-dom";
import "remixicon/fonts/remixicon.css"; // Import remix icon stylesheet

const Sidebar = ({
  categoryData,
  selectedSubcategory,
  setSelectedSubcategory,
  selectedCategory,
  setSelectedCategory,
  isSideBarOpen,
  setIsSideBarOpen,
  handleCategoryClick,
  handleSubcategoryClick,
  expandedCategories,
  reset
}) => {
  return (
    <>
      <div className="max-h-full grow overflow-hidden">
        <div className="max-h-screen w-full p-5">
          <div className="flex items-center justify-between lg:mb-2 xl:mb-0">
            <h3 className="font-bold">Category Filter</h3>
            {(selectedCategory || selectedSubcategory) && (
              <div onClick={reset} className="text-primary text-sm font-semibold cursor-pointer">
                Reset
              </div>
            )}
          </div>
          <ul className="text-xs xl:py-4">
            {categoryData?.map((category) => (
              <li key={category.id} className="rounded-md py-1">
                <button
                  className={`flex w-full justify-between items-center py-2 font-semibold text-sm transition-all ease-in-out duration-300 ${selectedCategory?.id === category.id ? "text-primary" : ""}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  <span>{category.categoryName}</span>
                  <i
                    className={`ri-arrow-${expandedCategories === category.id ? "up" : "down"}-s-line transition-transform duration-300 ease-in-out`}
                  ></i>
                </button>
                <ul
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedCategories === category.id ? "max-h-40 opacity-100" : "max-h-0 opacity-0"}`}
                >
                  {category.subCategories.map((subcategory) => (
                    <li key={subcategory.id} className="rounded-md py-1">
                      <button
                        className={`flex items-center py-2 text-sm font-semibold transition-colors ${selectedSubcategory?.subCategoryName === subcategory.subCategoryName ? "text-primary" : ""}`}
                        onClick={() => handleSubcategoryClick(subcategory)}
                      >
                        <span>{subcategory.subCategoryName}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Mobile Sidebar Overlay */}
      <div
        className={`fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${isSideBarOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
      >
        <section
          className={`fixed top-0 left-0 z-50 h-full max-w-md bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${isSideBarOpen ? "translate-x-0" : "-translate-x-full"}`}
        >
          <div className="flex flex-col h-full">
            <div className="fixed top-0 w-full flex items-center justify-between border-b border-gray-200 bg-white p-5">
              <Link to="/" className="flex items-center">
                <h1 className="text-lg font-semibold text-primary">Efactura</h1>
              </Link>
              <button
                onClick={() => setIsSideBarOpen(false)}
                className="flex items-center justify-center rounded-full bg-gray-200 h-7 w-7 hover:bg-primary hover:text-white transition-all duration-200"
              >
                <i className="ri-close-line"></i>
              </button>
            </div>
            <div className="pt-20 px-5">
              <ul className="text-xs xl:py-8">
                {categoryData?.map((category) => (
                  <li key={category.id} className="rounded-md py-1">
                    <button
                      className={`flex w-full justify-between items-center py-2 font-semibold text-sm transition-all ease-in-out duration-300 ${selectedCategory?.id === category.id ? "text-primary" : ""}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <span>{category.categoryName}</span>
                      <i
                        className={`ri-arrow-${expandedCategories === category.id ? "up" : "down"}-s-line transition-transform duration-300 ease-in-out`}
                      ></i>
                    </button>
                    <ul
                      className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedCategories === category.id ? "max-h-40 opacity-100" : "max-h-0 opacity-0"}`}
                    >
                      {category.subCategories.map((subcategory) => (
                        <li key={subcategory.id} className="rounded-md py-1 ml-4">
                          <button
                            className={`flex items-center py-2 text-sm font-semibold transition-colors ${selectedSubcategory?.subCategoryName === subcategory.subCategoryName ? "text-primary" : ""}`}
                            onClick={() => handleSubcategoryClick(subcategory)}
                          >
                            <span>{subcategory.subCategoryName}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Sidebar;

